.cui-focus-disabled :focus, .cui-focus-disabled :focus ~ .cui-control-indicator {
  outline: none !important;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #26323800;
  font-family: sans-serif;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #37474f;
  text-align: left;
  background-color: #eceff1;
  margin: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 15px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1565c0;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #104c90;
  text-decoration: underline;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .cui-h1, .cui-h2, .cui-h3, .cui-h4, .cui-h5, .cui-h6 {
  color: #37474f;
  margin: 0 0 15px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
}

h1, .cui-h1 {
  font-size: 35px;
}

h2, .cui-h2 {
  font-size: 28px;
}

h3, .cui-h3 {
  font-size: 21px;
}

h4, .cui-h4 {
  font-size: 17.5px;
}

h5, .cui-h5 {
  font-size: 14px;
}

h6, .cui-h6 {
  font-size: 10.5px;
}

p {
  margin: 0 0 15px;
}

.cui-text-muted {
  color: #90a4ae;
}

.cui-text-disabled {
  color: #cfd8dc;
}

code {
  color: #37474f;
  word-break: break-word;
  background: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  padding: 2.5px 5px;
  font-size: 13px;
}

pre {
  background: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
  background: none;
  border: none;
  padding: 15px 20px;
}

.cui-align-right {
  justify-content: flex-end;
  display: flex;
}

.cui-align-left {
  justify-content: flex-start;
  display: flex;
}

.cui-fluid {
  width: 100%;
}

.cui-disabled {
  cursor: not-allowed !important;
  opacity: .65 !important;
}

.cui-hidden {
  display: none;
}

.cui-control {
  color: #37474f;
  vertical-align: top;
  user-select: none;
  cursor: pointer;
  min-height: 16px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.cui-control .cui-control-indicator {
  width: 16px;
  height: 16px;
}

.cui-control input {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-control .cui-control-indicator {
  background: #fff;
  border-radius: 2px;
  margin: 0;
  transition: background .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
}

.cui-control input:focus ~ .cui-control-indicator {
  outline: 2px auto #64b5f6;
}

.cui-control:hover .cui-control-indicator, .cui-control:focus .cui-control-indicator {
  background: #f6f7f8;
  border: 1px solid #b0bec5;
}

.cui-control:active .cui-control-indicator {
  background: #eceff1;
  border: 1px solid #b0bec5;
}

.cui-control.cui-disabled .cui-control-indicator {
  user-select: none;
  cursor: not-allowed;
  opacity: .5;
  background: #fff;
}

.cui-control.cui-xs {
  min-height: 12px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 12px;
}

.cui-control.cui-xs .cui-control-indicator {
  width: 12px;
  height: 12px;
}

.cui-control.cui-sm {
  min-height: 14px;
  padding-left: 21px;
  font-size: 13px;
  line-height: 14px;
}

.cui-control.cui-sm .cui-control-indicator {
  width: 14px;
  height: 14px;
}

.cui-control.cui-lg {
  min-height: 18px;
  padding-left: 27px;
  font-size: 16px;
  line-height: 18px;
}

.cui-control.cui-lg .cui-control-indicator {
  width: 18px;
  height: 18px;
}

.cui-control.cui-xl {
  min-height: 20px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 20px;
}

.cui-control.cui-xl .cui-control-indicator {
  width: 20px;
  height: 20px;
}

.cui-control.cui-primary {
  color: #5c6bc0;
}

.cui-control.cui-positive {
  color: #4caf50;
}

.cui-control.cui-negative {
  color: #f44336;
}

.cui-control.cui-warning {
  color: #f57c00;
}

.cui-breadcrumb {
  align-items: center;
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-item {
  font-size: 14px;
}

.cui-breadcrumb .cui-breadcrumb-seperator {
  margin: 0 6px;
  font-size: 14px;
}

.cui-breadcrumb .cui-breadcrumb-item {
  color: #607d8b;
  text-decoration: none;
  transition: color .2s cubic-bezier(.4, 1, .75, .9);
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-item .cui-icon {
  color: #607d8b;
}

.cui-breadcrumb a.cui-breadcrumb-item, .cui-breadcrumb a.cui-breadcrumb-item .cui-icon {
  color: #b0bec5;
}

.cui-breadcrumb a.cui-breadcrumb-item:hover, .cui-breadcrumb a.cui-breadcrumb-item:hover .cui-icon {
  color: #607d8b;
}

.cui-breadcrumb .cui-breadcrumb-seperator {
  color: #607d8b;
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-seperator:last-child {
  display: none;
}

.cui-breadcrumb.cui-xs .cui-breadcrumb-item {
  font-size: 12px;
}

.cui-breadcrumb.cui-xs .cui-breadcrumb-seperator {
  margin: 0 5px;
  font-size: 12px;
}

.cui-breadcrumb.cui-sm .cui-breadcrumb-item {
  font-size: 13px;
}

.cui-breadcrumb.cui-sm .cui-breadcrumb-seperator {
  margin: 0 5.5px;
  font-size: 13px;
}

.cui-breadcrumb.cui-lg .cui-breadcrumb-item {
  font-size: 16px;
}

.cui-breadcrumb.cui-lg .cui-breadcrumb-seperator {
  margin: 0 7px;
  font-size: 16px;
}

.cui-breadcrumb.cui-xl .cui-breadcrumb-item {
  font-size: 18px;
}

.cui-breadcrumb.cui-xl .cui-breadcrumb-seperator {
  margin: 0 8px;
  font-size: 18px;
}

.cui-button {
  color: #607d8b;
  vertical-align: middle;
  cursor: pointer;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  padding: 0 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  text-decoration: none;
  transition: background .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  display: inline-flex;
  position: relative;
  box-shadow: 0 1px #eef1f2;
}

.cui-button.cui-compact {
  padding-left: 12px;
  padding-right: 12px;
}

.cui-button .cui-icon:not(:last-child), .cui-button .cui-button-label:not(:last-child) {
  margin-right: 10px;
}

.cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button.cui-button-icon {
  width: 40px;
}

.cui-button:hover {
  color: #546e7a;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #b0bec5;
  text-decoration: none;
}

.cui-button:active, .cui-button.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #b0bec5;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-button[disabled], .cui-button.cui-disabled {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-button.cui-align-center {
  justify-content: center;
}

.cui-button.cui-align-left {
  justify-content: flex-start;
}

.cui-button.cui-align-left .cui-icon:last-child {
  margin-left: auto !important;
}

.cui-button.cui-align-right {
  justify-content: flex-end;
}

.cui-button.cui-align-right .cui-icon:first-child {
  margin-right: auto !important;
}

.cui-button .cui-button-label, .cui-button .cui-button-sublabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  user-select: none;
  line-height: initial;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cui-button .cui-button-sublabel {
  color: #90a4ae;
}

.cui-button.cui-button-icon {
  flex-direction: column;
}

.cui-button.cui-loading {
  pointer-events: none;
}

.cui-button.cui-loading .cui-button-label, .cui-button.cui-loading .cui-button-sublabel, .cui-button.cui-loading svg {
  visibility: hidden;
}

.cui-button.cui-basic, .cui-button-group.cui-basic .cui-button {
  color: #607d8b;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: normal;
}

.cui-button.cui-basic:hover, .cui-button-group.cui-basic .cui-button:hover {
  color: #607d8b;
  background: #eff0f1;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button.cui-basic:active, .cui-button.cui-basic.cui-active, .cui-button-group.cui-basic .cui-button:active, .cui-button-group.cui-basic .cui-button.cui-active {
  box-shadow: none;
  background: #e7e8e9;
  border: 1px solid #0000;
}

.cui-button.cui-basic[disabled], .cui-button.cui-basic.cui-disabled, .cui-button-group.cui-basic .cui-button[disabled], .cui-button-group.cui-basic .cui-button.cui-disabled {
  color: #607d8b;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button.cui-rounded, .cui-button-group.cui-rounded .cui-button {
  border-radius: 30px;
}

.cui-button.cui-fluid, .cui-button-group.cui-fluid .cui-button {
  width: 100%;
}

.cui-button.cui-outlined, .cui-button-group.cui-outlined .cui-button {
  background: none;
}

.cui-button.cui-xs, .cui-button-group.cui-xs .cui-button, .cui-menu.cui-xs .cui-button {
  height: 30px;
  min-height: 30px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 30px;
}

.cui-button.cui-xs.cui-compact, .cui-button-group.cui-xs .cui-button.cui-compact, .cui-menu.cui-xs .cui-button.cui-compact {
  padding-left: 10px;
  padding-right: 10px;
}

.cui-button.cui-xs .cui-icon:not(:last-child), .cui-button.cui-xs .cui-button-label:not(:last-child), .cui-button-group.cui-xs .cui-button .cui-icon:not(:last-child), .cui-button-group.cui-xs .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-xs .cui-button .cui-icon:not(:last-child), .cui-menu.cui-xs .cui-button .cui-button-label:not(:last-child) {
  margin-right: 8px;
}

.cui-button.cui-xs .cui-button-sublabel:not(:last-child), .cui-button-group.cui-xs .cui-button .cui-button-sublabel:not(:last-child), .cui-menu.cui-xs .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button.cui-xs.cui-button-icon, .cui-button-group.cui-xs .cui-button.cui-button-icon, .cui-menu.cui-xs .cui-button.cui-button-icon {
  width: 30px;
}

.cui-button.cui-sm, .cui-button-group.cui-sm .cui-button, .cui-menu.cui-sm .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 13px;
  font-size: 13px;
  line-height: 35px;
}

.cui-button.cui-sm.cui-compact, .cui-button-group.cui-sm .cui-button.cui-compact, .cui-menu.cui-sm .cui-button.cui-compact {
  padding-left: 11px;
  padding-right: 11px;
}

.cui-button.cui-sm .cui-icon:not(:last-child), .cui-button.cui-sm .cui-button-label:not(:last-child), .cui-button-group.cui-sm .cui-button .cui-icon:not(:last-child), .cui-button-group.cui-sm .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-sm .cui-button .cui-icon:not(:last-child), .cui-menu.cui-sm .cui-button .cui-button-label:not(:last-child) {
  margin-right: 9px;
}

.cui-button.cui-sm .cui-button-sublabel:not(:last-child), .cui-button-group.cui-sm .cui-button .cui-button-sublabel:not(:last-child), .cui-menu.cui-sm .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button.cui-sm.cui-button-icon, .cui-button-group.cui-sm .cui-button.cui-button-icon, .cui-menu.cui-sm .cui-button.cui-button-icon {
  width: 35px;
}

.cui-button.cui-lg, .cui-button-group.cui-lg .cui-button, .cui-menu.cui-lg .cui-button {
  height: 45px;
  min-height: 45px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 45px;
}

.cui-button.cui-lg.cui-compact, .cui-button-group.cui-lg .cui-button.cui-compact, .cui-menu.cui-lg .cui-button.cui-compact {
  padding-left: 14px;
  padding-right: 14px;
}

.cui-button.cui-lg .cui-icon:not(:last-child), .cui-button.cui-lg .cui-button-label:not(:last-child), .cui-button-group.cui-lg .cui-button .cui-icon:not(:last-child), .cui-button-group.cui-lg .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-lg .cui-button .cui-icon:not(:last-child), .cui-menu.cui-lg .cui-button .cui-button-label:not(:last-child) {
  margin-right: 11px;
}

.cui-button.cui-lg .cui-button-sublabel:not(:last-child), .cui-button-group.cui-lg .cui-button .cui-button-sublabel:not(:last-child), .cui-menu.cui-lg .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 5px;
}

.cui-button.cui-lg.cui-button-icon, .cui-button-group.cui-lg .cui-button.cui-button-icon, .cui-menu.cui-lg .cui-button.cui-button-icon {
  width: 45px;
}

.cui-button.cui-xl, .cui-button-group.cui-xl .cui-button, .cui-menu.cui-xl .cui-button {
  height: 50px;
  min-height: 50px;
  padding: 0 19px;
  font-size: 18px;
  line-height: 50px;
}

.cui-button.cui-xl.cui-compact, .cui-button-group.cui-xl .cui-button.cui-compact, .cui-menu.cui-xl .cui-button.cui-compact {
  padding-left: 16px;
  padding-right: 16px;
}

.cui-button.cui-xl .cui-icon:not(:last-child), .cui-button.cui-xl .cui-button-label:not(:last-child), .cui-button-group.cui-xl .cui-button .cui-icon:not(:last-child), .cui-button-group.cui-xl .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-xl .cui-button .cui-icon:not(:last-child), .cui-menu.cui-xl .cui-button .cui-button-label:not(:last-child) {
  margin-right: 13px;
}

.cui-button.cui-xl .cui-button-sublabel:not(:last-child), .cui-button-group.cui-xl .cui-button .cui-button-sublabel:not(:last-child), .cui-menu.cui-xl .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 6px;
}

.cui-button.cui-xl.cui-button-icon, .cui-button-group.cui-xl .cui-button.cui-button-icon, .cui-menu.cui-xl .cui-button.cui-button-icon {
  width: 50px;
}

.cui-button.cui-primary .cui-button-sublabel, .cui-button.cui-positive .cui-button-sublabel, .cui-button.cui-negative .cui-button-sublabel, .cui-button.cui-warning .cui-button-sublabel {
  color: #eceff1;
}

.cui-button.cui-primary.cui-basic .cui-button-sublabel, .cui-button.cui-primary.cui-outlined .cui-button-sublabel, .cui-button.cui-positive.cui-basic .cui-button-sublabel, .cui-button.cui-positive.cui-outlined .cui-button-sublabel, .cui-button.cui-negative.cui-basic .cui-button-sublabel, .cui-button.cui-negative.cui-outlined .cui-button-sublabel, .cui-button.cui-warning.cui-basic .cui-button-sublabel, .cui-button.cui-warning.cui-outlined .cui-button-sublabel {
  color: #b0bec5;
}

.cui-button.cui-primary:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-positive:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-negative:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-warning:not(.cui-basic):not(.cui-outlined) .cui-spinner:after {
  border-color: #0000;
  border-top-color: #fff !important;
}

.cui-button.cui-primary, .cui-button-group.cui-primary .cui-button {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#5c6bc0 10.5%, #5766b6 100%);
  border: 1px solid #5360ad;
  font-weight: normal;
}

.cui-button.cui-primary:hover, .cui-button-group.cui-primary .cui-button:hover {
  color: #fff;
  background: linear-gradient(#5c6bc0 10.5%, #3f51b5 100%);
  border: 1px solid #4a569a;
  text-decoration: none;
}

.cui-button.cui-primary:active, .cui-button.cui-primary.cui-active, .cui-button-group.cui-primary .cui-button:active, .cui-button-group.cui-primary .cui-button.cui-active {
  background: linear-gradient(#5c6bc0 10.5%, #3949ab 100%);
  border: 1px solid #404b86;
  box-shadow: inset 0 4px 5px #4a569a;
}

.cui-button.cui-primary[disabled], .cui-button.cui-primary.cui-disabled, .cui-button-group.cui-primary .cui-button[disabled], .cui-button-group.cui-primary .cui-button.cui-disabled {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#5c6bc0 10.5%, #5766b6 100%);
  border: 1px solid #5360ad;
}

.cui-button.cui-primary .cui-icon, .cui-button-group.cui-primary .cui-button .cui-icon {
  color: #fff;
}

.cui-button.cui-primary.cui-basic, .cui-button-group.cui-primary.cui-basic .cui-button {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: normal;
}

.cui-button.cui-primary.cui-basic:hover, .cui-button-group.cui-primary.cui-basic .cui-button:hover {
  color: #5c6bc0;
  background: #eff0f9;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button.cui-primary.cui-basic:active, .cui-button.cui-primary.cui-basic.cui-active, .cui-button-group.cui-primary.cui-basic .cui-button:active, .cui-button-group.cui-primary.cui-basic .cui-button.cui-active {
  box-shadow: none;
  background: #dee1f2;
  border: 1px solid #0000;
}

.cui-button.cui-primary.cui-basic[disabled], .cui-button.cui-primary.cui-basic.cui-disabled, .cui-button-group.cui-primary.cui-basic .cui-button[disabled], .cui-button-group.cui-primary.cui-basic .cui-button.cui-disabled {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button.cui-primary.cui-basic .cui-icon, .cui-button-group.cui-primary.cui-basic .cui-button .cui-icon {
  color: #5c6bc0;
}

.cui-button.cui-primary.cui-outlined, .cui-button-group.cui-primary.cui-outlined .cui-button {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #5360ad;
  font-weight: normal;
}

.cui-button.cui-primary.cui-outlined:hover, .cui-button-group.cui-primary.cui-outlined .cui-button:hover {
  color: #5c6bc0;
  background: #f7f8fc;
  border: 1px solid #4a569a;
  text-decoration: none;
}

.cui-button.cui-primary.cui-outlined:active, .cui-button.cui-primary.cui-outlined.cui-active, .cui-button-group.cui-primary.cui-outlined .cui-button:active, .cui-button-group.cui-primary.cui-outlined .cui-button.cui-active {
  box-shadow: none;
  background: #eff0f9;
  border: 1px solid #404b86;
}

.cui-button.cui-primary.cui-outlined[disabled], .cui-button.cui-primary.cui-outlined.cui-disabled, .cui-button-group.cui-primary.cui-outlined .cui-button[disabled], .cui-button-group.cui-primary.cui-outlined .cui-button.cui-disabled {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #5360ad;
}

.cui-button.cui-primary.cui-outlined .cui-icon, .cui-button-group.cui-primary.cui-outlined .cui-button .cui-icon {
  color: #5c6bc0;
}

.cui-button.cui-positive, .cui-button-group.cui-positive .cui-button {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#4caf50 10.5%, #43a047 100%);
  border: 1px solid #3d8c40;
  font-weight: normal;
}

.cui-button.cui-positive:hover, .cui-button-group.cui-positive .cui-button:hover {
  color: #fff;
  background: linear-gradient(#4caf50 10.5%, #388e3c 100%);
  border: 1px solid #357b38;
  text-decoration: none;
}

.cui-button.cui-positive:active, .cui-button.cui-positive.cui-active, .cui-button-group.cui-positive .cui-button:active, .cui-button-group.cui-positive .cui-button.cui-active {
  background: linear-gradient(#4caf50 10.5%, #388e3c 100%);
  border: 1px solid #357b38;
  box-shadow: inset 0 4px 5px #3d8c40;
}

.cui-button.cui-positive[disabled], .cui-button.cui-positive.cui-disabled, .cui-button-group.cui-positive .cui-button[disabled], .cui-button-group.cui-positive .cui-button.cui-disabled {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#4caf50 10.5%, #43a047 100%);
  border: 1px solid #3d8c40;
}

.cui-button.cui-positive .cui-icon, .cui-button-group.cui-positive .cui-button .cui-icon {
  color: #fff;
}

.cui-button.cui-positive.cui-basic, .cui-button-group.cui-positive.cui-basic .cui-button {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: normal;
}

.cui-button.cui-positive.cui-basic:hover, .cui-button-group.cui-positive.cui-basic .cui-button:hover {
  color: #4caf50;
  background: #edf7ee;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button.cui-positive.cui-basic:active, .cui-button.cui-positive.cui-basic.cui-active, .cui-button-group.cui-positive.cui-basic .cui-button:active, .cui-button-group.cui-positive.cui-basic .cui-button.cui-active {
  box-shadow: none;
  background: #dbefdc;
  border: 1px solid #0000;
}

.cui-button.cui-positive.cui-basic[disabled], .cui-button.cui-positive.cui-basic.cui-disabled, .cui-button-group.cui-positive.cui-basic .cui-button[disabled], .cui-button-group.cui-positive.cui-basic .cui-button.cui-disabled {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button.cui-positive.cui-basic .cui-icon, .cui-button-group.cui-positive.cui-basic .cui-button .cui-icon {
  color: #4caf50;
}

.cui-button.cui-positive.cui-outlined, .cui-button-group.cui-positive.cui-outlined .cui-button {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #3d8c40;
  font-weight: normal;
}

.cui-button.cui-positive.cui-outlined:hover, .cui-button-group.cui-positive.cui-outlined .cui-button:hover {
  color: #4caf50;
  background: #f6fbf6;
  border: 1px solid #357b38;
  text-decoration: none;
}

.cui-button.cui-positive.cui-outlined:active, .cui-button.cui-positive.cui-outlined.cui-active, .cui-button-group.cui-positive.cui-outlined .cui-button:active, .cui-button-group.cui-positive.cui-outlined .cui-button.cui-active {
  box-shadow: none;
  background: #edf7ee;
  border: 1px solid #357b38;
}

.cui-button.cui-positive.cui-outlined[disabled], .cui-button.cui-positive.cui-outlined.cui-disabled, .cui-button-group.cui-positive.cui-outlined .cui-button[disabled], .cui-button-group.cui-positive.cui-outlined .cui-button.cui-disabled {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #3d8c40;
}

.cui-button.cui-positive.cui-outlined .cui-icon, .cui-button-group.cui-positive.cui-outlined .cui-button .cui-icon {
  color: #4caf50;
}

.cui-button.cui-negative, .cui-button-group.cui-negative .cui-button {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#f44336 10.5%, #e53935 100%);
  border: 1px solid #dc3c31;
  font-weight: normal;
}

.cui-button.cui-negative:hover, .cui-button-group.cui-negative .cui-button:hover {
  color: #fff;
  background: linear-gradient(#f44336 10.5%, #d32f2f 100%);
  border: 1px solid #c3362b;
  text-decoration: none;
}

.cui-button.cui-negative:active, .cui-button.cui-negative.cui-active, .cui-button-group.cui-negative .cui-button:active, .cui-button-group.cui-negative .cui-button.cui-active {
  background: linear-gradient(#f44336 10.5%, #d32f2f 100%);
  border: 1px solid #ab2f26;
  box-shadow: inset 0 4px 5px #c3362b;
}

.cui-button.cui-negative[disabled], .cui-button.cui-negative.cui-disabled, .cui-button-group.cui-negative .cui-button[disabled], .cui-button-group.cui-negative .cui-button.cui-disabled {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#f44336 10.5%, #e53935 100%);
  border: 1px solid #dc3c31;
}

.cui-button.cui-negative .cui-icon, .cui-button-group.cui-negative .cui-button .cui-icon {
  color: #fff;
}

.cui-button.cui-negative.cui-basic, .cui-button-group.cui-negative.cui-basic .cui-button {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: normal;
}

.cui-button.cui-negative.cui-basic:hover, .cui-button-group.cui-negative.cui-basic .cui-button:hover {
  color: #f44336;
  background: #feeceb;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button.cui-negative.cui-basic:active, .cui-button.cui-negative.cui-basic.cui-active, .cui-button-group.cui-negative.cui-basic .cui-button:active, .cui-button-group.cui-negative.cui-basic .cui-button.cui-active {
  box-shadow: none;
  background: #fdd9d7;
  border: 1px solid #0000;
}

.cui-button.cui-negative.cui-basic[disabled], .cui-button.cui-negative.cui-basic.cui-disabled, .cui-button-group.cui-negative.cui-basic .cui-button[disabled], .cui-button-group.cui-negative.cui-basic .cui-button.cui-disabled {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button.cui-negative.cui-basic .cui-icon, .cui-button-group.cui-negative.cui-basic .cui-button .cui-icon {
  color: #f44336;
}

.cui-button.cui-negative.cui-outlined, .cui-button-group.cui-negative.cui-outlined .cui-button {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #dc3c31;
  font-weight: normal;
}

.cui-button.cui-negative.cui-outlined:hover, .cui-button-group.cui-negative.cui-outlined .cui-button:hover {
  color: #f44336;
  background: #fef6f5;
  border: 1px solid #c3362b;
  text-decoration: none;
}

.cui-button.cui-negative.cui-outlined:active, .cui-button.cui-negative.cui-outlined.cui-active, .cui-button-group.cui-negative.cui-outlined .cui-button:active, .cui-button-group.cui-negative.cui-outlined .cui-button.cui-active {
  box-shadow: none;
  background: #feeceb;
  border: 1px solid #ab2f26;
}

.cui-button.cui-negative.cui-outlined[disabled], .cui-button.cui-negative.cui-outlined.cui-disabled, .cui-button-group.cui-negative.cui-outlined .cui-button[disabled], .cui-button-group.cui-negative.cui-outlined .cui-button.cui-disabled {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #dc3c31;
}

.cui-button.cui-negative.cui-outlined .cui-icon, .cui-button-group.cui-negative.cui-outlined .cui-button .cui-icon {
  color: #f44336;
}

.cui-button.cui-warning, .cui-button-group.cui-warning .cui-button {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#fb8c00 10.5%, #f57c00 100%);
  border: 1px solid #dd7000;
  font-weight: normal;
}

.cui-button.cui-warning:hover, .cui-button-group.cui-warning .cui-button:hover {
  color: #fff;
  background: linear-gradient(#fb8c00 10.5%, #ef6c00 100%);
  border: 1px solid #c46300;
  text-decoration: none;
}

.cui-button.cui-warning:active, .cui-button.cui-warning.cui-active, .cui-button-group.cui-warning .cui-button:active, .cui-button-group.cui-warning .cui-button.cui-active {
  background: linear-gradient(#fb8c00 10.5%, #ef6c00 100%);
  border: 1px solid #ac5700;
  box-shadow: inset 0 4px 5px #c46300;
}

.cui-button.cui-warning[disabled], .cui-button.cui-warning.cui-disabled, .cui-button-group.cui-warning .cui-button[disabled], .cui-button-group.cui-warning .cui-button.cui-disabled {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#fb8c00 10.5%, #f57c00 100%);
  border: 1px solid #dd7000;
}

.cui-button.cui-warning .cui-icon, .cui-button-group.cui-warning .cui-button .cui-icon {
  color: #fff;
}

.cui-button.cui-warning.cui-basic, .cui-button-group.cui-warning.cui-basic .cui-button {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: normal;
}

.cui-button.cui-warning.cui-basic:hover, .cui-button-group.cui-warning.cui-basic .cui-button:hover {
  color: #f57c00;
  background: #fef2e6;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button.cui-warning.cui-basic:active, .cui-button.cui-warning.cui-basic.cui-active, .cui-button-group.cui-warning.cui-basic .cui-button:active, .cui-button-group.cui-warning.cui-basic .cui-button.cui-active {
  box-shadow: none;
  background: #fde5cc;
  border: 1px solid #0000;
}

.cui-button.cui-warning.cui-basic[disabled], .cui-button.cui-warning.cui-basic.cui-disabled, .cui-button-group.cui-warning.cui-basic .cui-button[disabled], .cui-button-group.cui-warning.cui-basic .cui-button.cui-disabled {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button.cui-warning.cui-basic .cui-icon, .cui-button-group.cui-warning.cui-basic .cui-button .cui-icon {
  color: #f57c00;
}

.cui-button.cui-warning.cui-outlined, .cui-button-group.cui-warning.cui-outlined .cui-button {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #dd7000;
  font-weight: normal;
}

.cui-button.cui-warning.cui-outlined:hover, .cui-button-group.cui-warning.cui-outlined .cui-button:hover {
  color: #f57c00;
  background: #fff8f2;
  border: 1px solid #c46300;
  text-decoration: none;
}

.cui-button.cui-warning.cui-outlined:active, .cui-button.cui-warning.cui-outlined.cui-active, .cui-button-group.cui-warning.cui-outlined .cui-button:active, .cui-button-group.cui-warning.cui-outlined .cui-button.cui-active {
  box-shadow: none;
  background: #fef2e6;
  border: 1px solid #ac5700;
}

.cui-button.cui-warning.cui-outlined[disabled], .cui-button.cui-warning.cui-outlined.cui-disabled, .cui-button-group.cui-warning.cui-outlined .cui-button[disabled], .cui-button-group.cui-warning.cui-outlined .cui-button.cui-disabled {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #dd7000;
}

.cui-button.cui-warning.cui-outlined .cui-icon, .cui-button-group.cui-warning.cui-outlined .cui-button .cui-icon {
  color: #f57c00;
}

.cui-button-group {
  vertical-align: middle;
  display: inline-flex;
}

.cui-button-group .cui-button {
  flex: 1 0 auto;
  margin: 0;
  position: relative;
}

.cui-button-group .cui-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cui-button-group .cui-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.cui-button-group .cui-button:active, .cui-button-group .cui-button.cui-active, .cui-button-group .cui-button:focus, .cui-button-group .cui-button:hover {
  z-index: 10;
}

.cui-button-group.cui-basic .cui-button {
  border-radius: 3px;
  margin-right: 10px;
}

.cui-button-group.cui-basic .cui-button:not(:last-child):after {
  content: "";
  background: #c5cdd1;
  width: 1px;
  margin: 5px;
  display: inline-block;
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 100%;
}

.cui-button-group.cui-fluid {
  width: 100%;
}

.cui-button-group.cui-fluid .cui-button {
  flex: auto;
}

.cui-card {
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  max-width: 320px;
  padding: 15px;
  transition: box-shadow .2s ease-in-out;
}

.cui-card.cui-xs {
  padding: 12px;
}

.cui-card.cui-sm {
  padding: 13px;
}

.cui-card.cui-lg {
  padding: 17px;
}

.cui-card.cui-xl {
  padding: 20px;
}

.cui-card.cui-elevation-1 {
  box-shadow: 0 2px 4px -2px #435a6f4d;
}

.cui-card.cui-elevation-2 {
  box-shadow: 0 5px 8px -4px #435a6f4d;
}

.cui-card.cui-elevation-3 {
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-card.cui-elevation-4 {
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.cui-card.cui-card-interactive {
  cursor: pointer;
}

.cui-card.cui-card-interactive:hover, .cui-card.cui-card-interactive:focus {
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-card.cui-card-interactive:active {
  box-shadow: 0 2px 4px -2px #435a6f4d;
}

.cui-card.cui-fluid {
  max-width: none;
}

.cui-callout {
  color: #37474f;
  background: #f2f4f5;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  width: 100%;
  padding: 12px;
  display: block;
  position: relative;
}

.cui-callout .cui-callout-header {
  font-size: 14px;
}

.cui-callout .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout .cui-callout-content {
  font-size: 14px;
}

.cui-callout > .cui-icon {
  top: 14px;
}

.cui-callout .cui-callout-dismiss {
  right: 12px;
}

.cui-callout.cui-callout-icon .cui-callout-content, .cui-callout.cui-callout-icon .cui-callout-header {
  padding-left: 24px;
}

.cui-callout > .cui-icon {
  color: #37474f;
}

.cui-callout > .cui-icon, .cui-callout .cui-callout-dismiss {
  position: absolute;
}

.cui-callout .cui-callout-header {
  font-weight: bold;
}

.cui-callout.cui-fluid {
  width: 100%;
  display: block;
}

.cui-callout.cui-xs {
  padding: 10px;
}

.cui-callout.cui-xs .cui-callout-header {
  font-size: 12px;
}

.cui-callout.cui-xs .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout.cui-xs .cui-callout-content {
  font-size: 12px;
}

.cui-callout.cui-xs > .cui-icon {
  top: 12px;
}

.cui-callout.cui-xs .cui-callout-dismiss {
  right: 10px;
}

.cui-callout.cui-xs.cui-callout-icon .cui-callout-content, .cui-callout.cui-xs.cui-callout-icon .cui-callout-header {
  padding-left: 20px;
}

.cui-callout.cui-sm {
  padding: 11px;
}

.cui-callout.cui-sm .cui-callout-header {
  font-size: 13px;
}

.cui-callout.cui-sm .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout.cui-sm .cui-callout-content {
  font-size: 13px;
}

.cui-callout.cui-sm > .cui-icon {
  top: 13px;
}

.cui-callout.cui-sm .cui-callout-dismiss {
  right: 11px;
}

.cui-callout.cui-sm.cui-callout-icon .cui-callout-content, .cui-callout.cui-sm.cui-callout-icon .cui-callout-header {
  padding-left: 22px;
}

.cui-callout.cui-lg {
  padding: 14px;
}

.cui-callout.cui-lg .cui-callout-header {
  font-size: 16px;
}

.cui-callout.cui-lg .cui-callout-header:not(:only-child) {
  margin: 0 0 5px;
}

.cui-callout.cui-lg .cui-callout-content {
  font-size: 16px;
}

.cui-callout.cui-lg > .cui-icon {
  top: 16px;
}

.cui-callout.cui-lg .cui-callout-dismiss {
  right: 14px;
}

.cui-callout.cui-lg.cui-callout-icon .cui-callout-content, .cui-callout.cui-lg.cui-callout-icon .cui-callout-header {
  padding-left: 28px;
}

.cui-callout.cui-xl {
  padding: 16px;
}

.cui-callout.cui-xl .cui-callout-header {
  font-size: 18px;
}

.cui-callout.cui-xl .cui-callout-header:not(:only-child) {
  margin: 0 0 6px;
}

.cui-callout.cui-xl .cui-callout-content {
  font-size: 18px;
}

.cui-callout.cui-xl > .cui-icon {
  top: 19px;
}

.cui-callout.cui-xl .cui-callout-dismiss {
  right: 16px;
}

.cui-callout.cui-xl.cui-callout-icon .cui-callout-content, .cui-callout.cui-xl.cui-callout-icon .cui-callout-header {
  padding-left: 32px;
}

.cui-callout.cui-primary {
  color: #303f9f;
  background: #e8eaf6;
  border: 1px solid #5360ad;
}

.cui-callout.cui-primary > .cui-icon {
  color: #303f9f;
}

.cui-callout.cui-positive {
  color: #388e3c;
  background: #e8f5e9;
  border: 1px solid #3d8c40;
}

.cui-callout.cui-positive > .cui-icon {
  color: #388e3c;
}

.cui-callout.cui-negative {
  color: #d32f2f;
  background: #ffebee;
  border: 1px solid #dc3c31;
}

.cui-callout.cui-negative > .cui-icon {
  color: #d32f2f;
}

.cui-callout.cui-warning {
  color: #f57c00;
  background: #fff3e0;
  border: 1px solid #dd7000;
}

.cui-callout.cui-warning > .cui-icon {
  color: #f57c00;
}

.cui-checkbox input:checked ~ .cui-control-indicator:after {
  width: 5px;
  height: 8px;
  margin-top: -1px;
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox .cui-control-indicator {
  border: 1px solid #c5cdd1;
}

.cui-checkbox input:checked ~ .cui-control-indicator, .cui-checkbox input:indeterminate ~ .cui-control-indicator {
  background: #5c6bc0;
  border: 1px solid #404b86;
}

.cui-checkbox input:checked ~ .cui-control-indicator {
  color: #fff;
}

.cui-checkbox input:checked ~ .cui-control-indicator:after {
  content: " ";
  backface-visibility: hidden;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator {
  color: #fff;
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator:after {
  content: "";
  backface-visibility: hidden;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cui-checkbox.cui-xs input:checked ~ .cui-control-indicator:after {
  width: 4px;
  height: 6px;
  margin-top: -1px;
}

.cui-checkbox.cui-xs input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-sm input:checked ~ .cui-control-indicator:after {
  width: 4px;
  height: 7px;
  margin-top: -1px;
}

.cui-checkbox.cui-sm input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-lg input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 9px;
  margin-top: -1px;
}

.cui-checkbox.cui-lg input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-xl input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 10px;
  margin-top: -1px;
}

.cui-checkbox.cui-xl input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-primary .cui-control-indicator {
  border: 1px solid #5360ad;
}

.cui-checkbox.cui-primary input:checked ~ .cui-control-indicator, .cui-checkbox.cui-primary input:indeterminate ~ .cui-control-indicator {
  background: #5c6bc0;
  border: 1px solid #404b86;
}

.cui-checkbox.cui-positive .cui-control-indicator {
  border: 1px solid #3d8c40;
}

.cui-checkbox.cui-positive input:checked ~ .cui-control-indicator, .cui-checkbox.cui-positive input:indeterminate ~ .cui-control-indicator {
  background: #4caf50;
  border: 1px solid #357b38;
}

.cui-checkbox.cui-negative .cui-control-indicator {
  border: 1px solid #dc3c31;
}

.cui-checkbox.cui-negative input:checked ~ .cui-control-indicator, .cui-checkbox.cui-negative input:indeterminate ~ .cui-control-indicator {
  background: #f44336;
  border: 1px solid #ab2f26;
}

.cui-checkbox.cui-warning .cui-control-indicator {
  border: 1px solid #dd7000;
}

.cui-checkbox.cui-warning input:checked ~ .cui-control-indicator, .cui-checkbox.cui-warning input:indeterminate ~ .cui-control-indicator {
  background: #f57c00;
  border: 1px solid #ac5700;
}

.cui-collapse {
  height: 0;
  overflow: hidden;
}

.cui-control-group {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-flex;
}

.cui-control-group:last-child {
  margin: 0;
}

.cui-control-group > :first-child {
  border-radius: 3px 0 0 3px;
  margin-right: -1px;
}

.cui-control-group > :last-child {
  border-radius: 0 3px 3px 0;
}

.cui-control-group > :only-child {
  border-radius: 3px;
  margin: 0;
}

.cui-control-group > :not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-right: -1px;
}

.cui-control-group .cui-select select, .cui-control-group .cui-input input, .cui-control-group .cui-custom-select button {
  border-radius: inherit;
}

.cui-control-group :focus, .cui-control-group :hover, .cui-control-group :active, .cui-control-group .cui-active, .cui-control-group :focus {
  z-index: 1;
}

.cui-control-group.cui-fluid > * {
  flex: auto;
}

.cui-custom-select {
  display: inline-block;
}

.cui-custom-select .cui-overlay {
  position: relative;
}

.cui-custom-select .cui-popover {
  width: 100%;
  transform: none !important;
}

.cui-custom-select .cui-popover-content {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  padding: 0;
}

.cui-custom-select-trigger.cui-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.cui-custom-select-input {
  display: none;
}

.cui-dialog {
  z-index: 20;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.cui-dialog.cui-basic .cui-dialog-header, .cui-dialog.cui-basic .cui-dialog-footer {
  background: #fff;
  border: none;
}

.cui-dialog-content {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  flex-direction: column;
  width: 600px;
  max-width: 97%;
  max-height: 97%;
  display: flex;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.fade-enter .cui-dialog-content {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.95);
}

.fade-enter-active .cui-dialog-content {
  opacity: 1;
  transform: scale(1);
}

.fade-exit .cui-dialog-content {
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
}

.fade-exit-active .cui-dialog-content {
  opacity: 0;
  transform: scale(.95);
}

.cui-dialog-header {
  background: #eceff1;
  border-bottom: 1px solid #c5cdd1;
  border-radius: 4.5px 4.5px 0 0;
  flex: none;
  align-items: center;
  min-height: 40px;
  padding: 15px;
  display: flex;
}

.cui-dialog-header h3 {
  font-weight: normal;
  line-height: inherit;
  color: #37474f;
  flex: auto;
  margin-bottom: 1px;
}

.cui-dialog-close-button {
  flex: none;
}

.cui-dialog-body {
  background: #fff;
  flex: auto;
  padding: 15px;
  overflow: auto;
}

.cui-dialog-footer {
  background: #eceff1;
  border-top: 1px solid #c5cdd1;
  border-radius: 0 0 4.5px 4.5px;
  flex: none;
  padding: 15px;
}

.cui-dialog-footer .cui-button {
  margin-right: 10px;
}

.cui-dialog-footer .cui-button:last-child {
  margin: 0;
}

.cui-overlay-inline .cui-dialog {
  position: absolute;
}

.cui-drawer {
  z-index: 20;
  position: fixed;
}

.cui-drawer-right, .cui-drawer-left {
  height: 100%;
  top: 0;
}

.cui-drawer-right .cui-drawer-content, .cui-drawer-left .cui-drawer-content {
  min-width: 320px;
  max-width: 97%;
  height: 100%;
}

.cui-drawer-right {
  right: 0;
}

.fade-enter .cui-drawer-right .cui-drawer-content, .fade-exit-active .cui-drawer-right .cui-drawer-content {
  transform: translateX(100%);
}

.fade-enter-active .cui-drawer-right .cui-drawer-content {
  transform: translateX(0);
}

.cui-drawer-left {
  left: 0;
}

.fade-enter .cui-drawer-left .cui-drawer-content, .fade-exit-active .cui-drawer-left .cui-drawer-content {
  transform: translateX(-100%);
}

.fade-enter-active .cui-drawer-left .cui-drawer-content {
  transform: translateX(0);
}

.cui-drawer-top, .cui-drawer-bottom {
  width: 100%;
  left: 0;
}

.cui-drawer-top .cui-drawer-content, .cui-drawer-bottom .cui-drawer-content {
  width: 100%;
  min-height: 160px;
  max-height: 97%;
}

.cui-drawer-top {
  top: 0;
}

.fade-enter .cui-drawer-top .cui-drawer-content, .fade-exit-active .cui-drawer-top .cui-drawer-content {
  transform: translateY(-100%);
}

.fade-enter-active .cui-drawer-top .cui-drawer-content {
  transform: translateY(0);
}

.cui-drawer-bottom {
  bottom: 0;
}

.fade-enter .cui-drawer-bottom .cui-drawer-content, .fade-exit-active .cui-drawer-bottom .cui-drawer-content {
  transform: translateY(100%);
}

.fade-enter-active .cui-drawer-bottom .cui-drawer-content {
  transform: translateY(0);
}

.cui-overlay-inline .cui-drawer {
  position: absolute;
}

.cui-drawer-content {
  z-index: 20;
  background: #fff;
  border: 1px solid #c5cdd1;
  padding: 15px;
  position: relative;
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.fade-enter .cui-drawer-content {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-drawer-content {
  opacity: 1;
}

.fade-exit .cui-drawer-content {
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
}

.fade-exit-active .cui-drawer-content {
  opacity: 0;
}

.cui-empty-state {
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.cui-empty-state.cui-empty-state-fill {
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-empty-state-icon .cui-icon svg {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  margin-bottom: 15px;
}

.cui-empty-state-header {
  font-size: 16px;
  font-weight: bold;
}

.cui-empty-state-header:not(:last-child) {
  margin-bottom: 10px;
}

.cui-empty-state-content {
  text-align: center;
}

.cui-form .cui-form-group {
  margin-bottom: 15px;
}

.cui-form .cui-form-group .cui-input, .cui-form .cui-form-group .cui-input-file, .cui-form .cui-form-group .cui-custom-select {
  width: 100%;
}

.cui-form .cui-form-group:last-child {
  margin: 0;
}

.cui-form .cui-form-group.cui-disabled {
  opacity: .75;
  pointer-events: none;
}

.cui-form .cui-form-label {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
}

.cui-grid {
  flex-flow: wrap;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.cui-grid-align-top {
  align-items: flex-start;
}

.cui-grid-align-middle {
  align-items: center;
}

.cui-grid-align-bottom {
  align-items: flex-end;
}

.cui-grid-justify-start {
  justify-content: flex-start;
}

.cui-grid-justify-end {
  justify-content: flex-end;
}

.cui-grid-justify-center {
  justify-content: center;
}

.cui-grid-justify-space-around {
  justify-content: space-around;
}

.cui-grid-justify-space-between {
  justify-content: space-between;
}

[class*="cui-col"] {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.cui-col-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

.cui-col-offset-1 {
  margin-left: 8.33333%;
}

.cui-col-order-1 {
  order: 1;
}

.cui-col-2 {
  flex-basis: 16.6667%;
  max-width: 16.6667%;
}

.cui-col-offset-2 {
  margin-left: 16.6667%;
}

.cui-col-order-2 {
  order: 2;
}

.cui-col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.cui-col-offset-3 {
  margin-left: 25%;
}

.cui-col-order-3 {
  order: 3;
}

.cui-col-4 {
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.cui-col-offset-4 {
  margin-left: 33.3333%;
}

.cui-col-order-4 {
  order: 4;
}

.cui-col-5 {
  flex-basis: 41.6667%;
  max-width: 41.6667%;
}

.cui-col-offset-5 {
  margin-left: 41.6667%;
}

.cui-col-order-5 {
  order: 5;
}

.cui-col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.cui-col-offset-6 {
  margin-left: 50%;
}

.cui-col-order-6 {
  order: 6;
}

.cui-col-7 {
  flex-basis: 58.3333%;
  max-width: 58.3333%;
}

.cui-col-offset-7 {
  margin-left: 58.3333%;
}

.cui-col-order-7 {
  order: 7;
}

.cui-col-8 {
  flex-basis: 66.6667%;
  max-width: 66.6667%;
}

.cui-col-offset-8 {
  margin-left: 66.6667%;
}

.cui-col-order-8 {
  order: 8;
}

.cui-col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.cui-col-offset-9 {
  margin-left: 75%;
}

.cui-col-order-9 {
  order: 9;
}

.cui-col-10 {
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.cui-col-offset-10 {
  margin-left: 83.3333%;
}

.cui-col-order-10 {
  order: 10;
}

.cui-col-11 {
  flex-basis: 91.6667%;
  max-width: 91.6667%;
}

.cui-col-offset-11 {
  margin-left: 91.6667%;
}

.cui-col-order-11 {
  order: 11;
}

.cui-col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.cui-col-offset-12 {
  margin-left: 100%;
}

.cui-col-order-12 {
  order: 12;
}

@media only screen and (width >= 0) {
  .cui-col-xs-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-xs-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-xs-order-1 {
    order: 1;
  }

  .cui-col-xs-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-xs-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-xs-order-2 {
    order: 2;
  }

  .cui-col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-xs-offset-3 {
    margin-left: 25%;
  }

  .cui-col-xs-order-3 {
    order: 3;
  }

  .cui-col-xs-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-xs-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-xs-order-4 {
    order: 4;
  }

  .cui-col-xs-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-xs-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-xs-order-5 {
    order: 5;
  }

  .cui-col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-xs-offset-6 {
    margin-left: 50%;
  }

  .cui-col-xs-order-6 {
    order: 6;
  }

  .cui-col-xs-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-xs-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-xs-order-7 {
    order: 7;
  }

  .cui-col-xs-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-xs-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-xs-order-8 {
    order: 8;
  }

  .cui-col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-xs-offset-9 {
    margin-left: 75%;
  }

  .cui-col-xs-order-9 {
    order: 9;
  }

  .cui-col-xs-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-xs-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-xs-order-10 {
    order: 10;
  }

  .cui-col-xs-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-xs-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-xs-order-11 {
    order: 11;
  }

  .cui-col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-xs-offset-12 {
    margin-left: 100%;
  }

  .cui-col-xs-order-12 {
    order: 12;
  }
}

@media only screen and (width >= 576px) {
  .cui-col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-sm-order-1 {
    order: 1;
  }

  .cui-col-sm-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-sm-order-2 {
    order: 2;
  }

  .cui-col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-sm-offset-3 {
    margin-left: 25%;
  }

  .cui-col-sm-order-3 {
    order: 3;
  }

  .cui-col-sm-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-sm-order-4 {
    order: 4;
  }

  .cui-col-sm-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-sm-order-5 {
    order: 5;
  }

  .cui-col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-sm-offset-6 {
    margin-left: 50%;
  }

  .cui-col-sm-order-6 {
    order: 6;
  }

  .cui-col-sm-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-sm-order-7 {
    order: 7;
  }

  .cui-col-sm-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-sm-order-8 {
    order: 8;
  }

  .cui-col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-sm-offset-9 {
    margin-left: 75%;
  }

  .cui-col-sm-order-9 {
    order: 9;
  }

  .cui-col-sm-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-sm-order-10 {
    order: 10;
  }

  .cui-col-sm-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-sm-order-11 {
    order: 11;
  }

  .cui-col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-sm-offset-12 {
    margin-left: 100%;
  }

  .cui-col-sm-order-12 {
    order: 12;
  }
}

@media only screen and (width >= 768px) {
  .cui-col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-md-order-1 {
    order: 1;
  }

  .cui-col-md-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-md-order-2 {
    order: 2;
  }

  .cui-col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-md-offset-3 {
    margin-left: 25%;
  }

  .cui-col-md-order-3 {
    order: 3;
  }

  .cui-col-md-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-md-order-4 {
    order: 4;
  }

  .cui-col-md-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-md-order-5 {
    order: 5;
  }

  .cui-col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-md-offset-6 {
    margin-left: 50%;
  }

  .cui-col-md-order-6 {
    order: 6;
  }

  .cui-col-md-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-md-order-7 {
    order: 7;
  }

  .cui-col-md-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-md-order-8 {
    order: 8;
  }

  .cui-col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-md-offset-9 {
    margin-left: 75%;
  }

  .cui-col-md-order-9 {
    order: 9;
  }

  .cui-col-md-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-md-order-10 {
    order: 10;
  }

  .cui-col-md-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-md-order-11 {
    order: 11;
  }

  .cui-col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-md-offset-12 {
    margin-left: 100%;
  }

  .cui-col-md-order-12 {
    order: 12;
  }
}

@media only screen and (width >= 992px) {
  .cui-col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-lg-order-1 {
    order: 1;
  }

  .cui-col-lg-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-lg-order-2 {
    order: 2;
  }

  .cui-col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-lg-offset-3 {
    margin-left: 25%;
  }

  .cui-col-lg-order-3 {
    order: 3;
  }

  .cui-col-lg-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-lg-order-4 {
    order: 4;
  }

  .cui-col-lg-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-lg-order-5 {
    order: 5;
  }

  .cui-col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-lg-offset-6 {
    margin-left: 50%;
  }

  .cui-col-lg-order-6 {
    order: 6;
  }

  .cui-col-lg-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-lg-order-7 {
    order: 7;
  }

  .cui-col-lg-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-lg-order-8 {
    order: 8;
  }

  .cui-col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-lg-offset-9 {
    margin-left: 75%;
  }

  .cui-col-lg-order-9 {
    order: 9;
  }

  .cui-col-lg-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-lg-order-10 {
    order: 10;
  }

  .cui-col-lg-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-lg-order-11 {
    order: 11;
  }

  .cui-col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-lg-offset-12 {
    margin-left: 100%;
  }

  .cui-col-lg-order-12 {
    order: 12;
  }
}

@media only screen and (width >= 1200px) {
  .cui-col-xl-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-xl-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-xl-order-1 {
    order: 1;
  }

  .cui-col-xl-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-xl-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-xl-order-2 {
    order: 2;
  }

  .cui-col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-xl-offset-3 {
    margin-left: 25%;
  }

  .cui-col-xl-order-3 {
    order: 3;
  }

  .cui-col-xl-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-xl-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-xl-order-4 {
    order: 4;
  }

  .cui-col-xl-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-xl-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-xl-order-5 {
    order: 5;
  }

  .cui-col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-xl-offset-6 {
    margin-left: 50%;
  }

  .cui-col-xl-order-6 {
    order: 6;
  }

  .cui-col-xl-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-xl-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-xl-order-7 {
    order: 7;
  }

  .cui-col-xl-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-xl-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-xl-order-8 {
    order: 8;
  }

  .cui-col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-xl-offset-9 {
    margin-left: 75%;
  }

  .cui-col-xl-order-9 {
    order: 9;
  }

  .cui-col-xl-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-xl-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-xl-order-10 {
    order: 10;
  }

  .cui-col-xl-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-xl-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-xl-order-11 {
    order: 11;
  }

  .cui-col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-xl-offset-12 {
    margin-left: 100%;
  }

  .cui-col-xl-order-12 {
    order: 12;
  }
}

.cui-icon {
  vertical-align: middle;
  color: #546e7a;
  display: inline-flex;
  position: relative;
}

.cui-icon svg {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  display: block;
}

.cui-icon.cui-icon-action:hover {
  cursor: pointer;
  color: #263238;
}

.cui-icon.cui-xs svg, .cui-xs .cui-icon svg, .cui-icon.cui-sm svg, .cui-sm .cui-icon svg {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
}

.cui-icon.cui-lg svg, .cui-lg .cui-icon svg {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
}

.cui-icon.cui-xl svg, .cui-xl .cui-icon svg {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.cui-icon.cui-primary, .cui-primary > .cui-icon {
  color: #5c6bc0;
}

.cui-icon.cui-primary.cui-icon-action:hover, .cui-primary > .cui-icon.cui-icon-action:hover {
  color: #5360ad;
}

.cui-icon.cui-positive, .cui-positive > .cui-icon {
  color: #4caf50;
}

.cui-icon.cui-positive.cui-icon-action:hover, .cui-positive > .cui-icon.cui-icon-action:hover {
  color: #449e48;
}

.cui-icon.cui-negative, .cui-negative > .cui-icon {
  color: #f44336;
}

.cui-icon.cui-negative.cui-icon-action:hover, .cui-negative > .cui-icon.cui-icon-action:hover {
  color: #dc3c31;
}

.cui-icon.cui-warning, .cui-warning > .cui-icon {
  color: #f57c00;
}

.cui-icon.cui-warning.cui-icon-action:hover, .cui-warning > .cui-icon.cui-icon-action:hover {
  color: #dd7000;
}

.cui-input {
  vertical-align: middle;
  background: #fff;
  display: inline-flex;
  position: relative;
}

.cui-input input, .cui-input textarea {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-input input:not(:first-child), .cui-input textarea:not(:first-child) {
  padding-left: 36px;
}

.cui-input input:not(:last-child), .cui-input textarea:not(:last-child) {
  padding-right: 36px;
}

.cui-input > .cui-button:first-child:not(input), .cui-input > .cui-spinner:first-child:not(input), .cui-input > .cui-icon:first-child:not(input), .cui-input > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-input > .cui-button:last-child, .cui-input > .cui-spinner:last-child, .cui-input > .cui-icon:last-child, .cui-input > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-input > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-input input, .cui-input textarea {
  border: 1px solid #c5cdd1;
}

.cui-input input:focus, .cui-input textarea:focus {
  border: 1px solid #5360ad;
}

.cui-input input, .cui-input textarea {
  color: #37474f;
  background: none;
  border-radius: 3px;
  outline: none;
  width: 100%;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
  box-shadow: 0 1px #eef1f2;
}

.cui-input input::placeholder, .cui-input textarea::placeholder {
  color: #b0bec5;
}

.cui-input input:focus::placeholder, .cui-input textarea:focus::placeholder {
  color: #90a4ae;
}

.cui-input input::-ms-clear {
  display: none;
}

.cui-input textarea::-ms-clear {
  display: none;
}

.cui-input > .cui-button, .cui-input > .cui-spinner, .cui-input > .cui-icon, .cui-input > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-input > .cui-button:first-child, .cui-input > .cui-spinner:first-child, .cui-input > .cui-icon:first-child, .cui-input > .cui-tag:first-child {
  left: 0;
}

.cui-input > .cui-button:last-child, .cui-input > .cui-spinner:last-child, .cui-input > .cui-icon:last-child, .cui-input > .cui-tag:last-child {
  right: 0;
}

.cui-input.cui-xs input, .cui-input.cui-xs textarea {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-input.cui-xs input:not(:first-child), .cui-input.cui-xs textarea:not(:first-child) {
  padding-left: 30px;
}

.cui-input.cui-xs input:not(:last-child), .cui-input.cui-xs textarea:not(:last-child) {
  padding-right: 30px;
}

.cui-input.cui-xs > .cui-button:first-child:not(input), .cui-input.cui-xs > .cui-spinner:first-child:not(input), .cui-input.cui-xs > .cui-icon:first-child:not(input), .cui-input.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-input.cui-xs > .cui-button:last-child, .cui-input.cui-xs > .cui-spinner:last-child, .cui-input.cui-xs > .cui-icon:last-child, .cui-input.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-input.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-input.cui-sm input, .cui-input.cui-sm textarea {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-input.cui-sm input:not(:first-child), .cui-input.cui-sm textarea:not(:first-child) {
  padding-left: 33px;
}

.cui-input.cui-sm input:not(:last-child), .cui-input.cui-sm textarea:not(:last-child) {
  padding-right: 33px;
}

.cui-input.cui-sm > .cui-button:first-child:not(input), .cui-input.cui-sm > .cui-spinner:first-child:not(input), .cui-input.cui-sm > .cui-icon:first-child:not(input), .cui-input.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-input.cui-sm > .cui-button:last-child, .cui-input.cui-sm > .cui-spinner:last-child, .cui-input.cui-sm > .cui-icon:last-child, .cui-input.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-input.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-input.cui-lg input, .cui-input.cui-lg textarea {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-input.cui-lg input:not(:first-child), .cui-input.cui-lg textarea:not(:first-child) {
  padding-left: 42px;
}

.cui-input.cui-lg input:not(:last-child), .cui-input.cui-lg textarea:not(:last-child) {
  padding-right: 42px;
}

.cui-input.cui-lg > .cui-button:first-child:not(input), .cui-input.cui-lg > .cui-spinner:first-child:not(input), .cui-input.cui-lg > .cui-icon:first-child:not(input), .cui-input.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-input.cui-lg > .cui-button:last-child, .cui-input.cui-lg > .cui-spinner:last-child, .cui-input.cui-lg > .cui-icon:last-child, .cui-input.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-input.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-input.cui-xl input, .cui-input.cui-xl textarea {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-input.cui-xl input:not(:first-child), .cui-input.cui-xl textarea:not(:first-child) {
  padding-left: 48px;
}

.cui-input.cui-xl input:not(:last-child), .cui-input.cui-xl textarea:not(:last-child) {
  padding-right: 48px;
}

.cui-input.cui-xl > .cui-button:first-child:not(input), .cui-input.cui-xl > .cui-spinner:first-child:not(input), .cui-input.cui-xl > .cui-icon:first-child:not(input), .cui-input.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-input.cui-xl > .cui-button:last-child, .cui-input.cui-xl > .cui-spinner:last-child, .cui-input.cui-xl > .cui-icon:last-child, .cui-input.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-input.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-input.cui-primary input, .cui-input.cui-primary textarea {
  border: 1px solid #5360ad;
}

.cui-input.cui-primary input:focus, .cui-input.cui-primary textarea:focus {
  border: 1px solid #4a569a;
}

.cui-input.cui-positive input, .cui-input.cui-positive textarea {
  border: 1px solid #3d8c40;
}

.cui-input.cui-positive input:focus, .cui-input.cui-positive textarea:focus {
  border: 1px solid #357b38;
}

.cui-input.cui-negative input, .cui-input.cui-negative textarea {
  border: 1px solid #dc3c31;
}

.cui-input.cui-negative input:focus, .cui-input.cui-negative textarea:focus {
  border: 1px solid #c3362b;
}

.cui-input.cui-warning input, .cui-input.cui-warning textarea {
  border: 1px solid #dd7000;
}

.cui-input.cui-warning input:focus, .cui-input.cui-warning textarea:focus {
  border: 1px solid #c46300;
}

.cui-input.cui-disabled input, .cui-input.cui-disabled textarea, .cui-input.cui-disabled .cui-button, .cui-input.cui-disabled .cui-icon {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-input.cui-basic input, .cui-input.cui-basic input:focus, .cui-input.cui-basic textarea, .cui-input.cui-basic textarea:focus {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.cui-input-file {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  cursor: pointer;
  background: #fff;
  min-width: 200px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.cui-input-file .cui-input-file-content {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-input-file .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 36px;
}

.cui-input-file .cui-input-file-content:not(:last-child) {
  padding-right: 36px;
}

.cui-input-file > .cui-button:first-child:not(input), .cui-input-file > .cui-spinner:first-child:not(input), .cui-input-file > .cui-icon:first-child:not(input), .cui-input-file > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-input-file > .cui-button:last-child, .cui-input-file > .cui-spinner:last-child, .cui-input-file > .cui-icon:last-child, .cui-input-file > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-input-file > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-input-file .cui-input-file-content {
  border: 1px solid #c5cdd1;
}

.cui-input-file .cui-input-file-content:focus {
  border: 1px solid #b0bec5;
}

.cui-input-file input {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  outline: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-input-file .cui-input-file-content {
  color: #b0bec5;
  background: none;
  border-radius: 3px;
  width: 100%;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
}

.cui-input-file .cui-input-file-content.cui-input-file-text {
  color: #37474f;
}

.cui-input-file .cui-input-file-button {
  pointer-events: none;
}

.cui-input-file > .cui-button, .cui-input-file > .cui-spinner, .cui-input-file > .cui-icon, .cui-input-file > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-input-file > .cui-button:first-child, .cui-input-file > .cui-spinner:first-child, .cui-input-file > .cui-icon:first-child, .cui-input-file > .cui-tag:first-child {
  left: 0;
}

.cui-input-file > .cui-button:last-child, .cui-input-file > .cui-spinner:last-child, .cui-input-file > .cui-icon:last-child, .cui-input-file > .cui-tag:last-child {
  right: 0;
}

.cui-input-file.cui-xs .cui-input-file-content {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-input-file.cui-xs .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 30px;
}

.cui-input-file.cui-xs .cui-input-file-content:not(:last-child) {
  padding-right: 30px;
}

.cui-input-file.cui-xs > .cui-button:first-child:not(input), .cui-input-file.cui-xs > .cui-spinner:first-child:not(input), .cui-input-file.cui-xs > .cui-icon:first-child:not(input), .cui-input-file.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-input-file.cui-xs > .cui-button:last-child, .cui-input-file.cui-xs > .cui-spinner:last-child, .cui-input-file.cui-xs > .cui-icon:last-child, .cui-input-file.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-input-file.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-input-file.cui-sm .cui-input-file-content {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-input-file.cui-sm .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 33px;
}

.cui-input-file.cui-sm .cui-input-file-content:not(:last-child) {
  padding-right: 33px;
}

.cui-input-file.cui-sm > .cui-button:first-child:not(input), .cui-input-file.cui-sm > .cui-spinner:first-child:not(input), .cui-input-file.cui-sm > .cui-icon:first-child:not(input), .cui-input-file.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-input-file.cui-sm > .cui-button:last-child, .cui-input-file.cui-sm > .cui-spinner:last-child, .cui-input-file.cui-sm > .cui-icon:last-child, .cui-input-file.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-input-file.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-input-file.cui-lg .cui-input-file-content {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-input-file.cui-lg .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 42px;
}

.cui-input-file.cui-lg .cui-input-file-content:not(:last-child) {
  padding-right: 42px;
}

.cui-input-file.cui-lg > .cui-button:first-child:not(input), .cui-input-file.cui-lg > .cui-spinner:first-child:not(input), .cui-input-file.cui-lg > .cui-icon:first-child:not(input), .cui-input-file.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-input-file.cui-lg > .cui-button:last-child, .cui-input-file.cui-lg > .cui-spinner:last-child, .cui-input-file.cui-lg > .cui-icon:last-child, .cui-input-file.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-input-file.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-input-file.cui-xl .cui-input-file-content {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-input-file.cui-xl .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 48px;
}

.cui-input-file.cui-xl .cui-input-file-content:not(:last-child) {
  padding-right: 48px;
}

.cui-input-file.cui-xl > .cui-button:first-child:not(input), .cui-input-file.cui-xl > .cui-spinner:first-child:not(input), .cui-input-file.cui-xl > .cui-icon:first-child:not(input), .cui-input-file.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-input-file.cui-xl > .cui-button:last-child, .cui-input-file.cui-xl > .cui-spinner:last-child, .cui-input-file.cui-xl > .cui-icon:last-child, .cui-input-file.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-input-file.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-input-file.cui-primary .cui-input-file-content {
  border: 1px solid #5360ad;
}

.cui-input-file.cui-primary .cui-input-file-content:focus {
  border: 1px solid #4a569a;
}

.cui-input-file.cui-positive .cui-input-file-content {
  border: 1px solid #3d8c40;
}

.cui-input-file.cui-positive .cui-input-file-content:focus {
  border: 1px solid #357b38;
}

.cui-input-file.cui-negative .cui-input-file-content {
  border: 1px solid #dc3c31;
}

.cui-input-file.cui-negative .cui-input-file-content:focus {
  border: 1px solid #c3362b;
}

.cui-input-file.cui-warning .cui-input-file-content {
  border: 1px solid #dd7000;
}

.cui-input-file.cui-warning .cui-input-file-content:focus {
  border: 1px solid #c46300;
}

.cui-input-popover .cui-input, .cui-input-popover .cui-textarea {
  margin-bottom: 20px;
}

.cui-list {
  background: #fff;
  width: 100%;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

.cui-list .cui-list-item {
  padding: 10px;
  font-size: 14px;
}

.cui-list .cui-list-item-content-left {
  padding-right: 10px;
}

.cui-list .cui-list-item-content-right {
  padding-left: 10px;
}

.cui-list .cui-list-item:hover {
  cursor: default;
  background: none;
}

.cui-list.cui-interactive .cui-list-item:hover {
  cursor: pointer;
  background: #f1f3f5;
}

.cui-list-item {
  color: #37474f;
  cursor: pointer;
  border-bottom: 1px solid #c5cdd1;
  align-items: center;
  font-weight: normal;
  display: flex;
  position: relative;
}

.cui-list-item:hover, .cui-list-item.cui-active {
  background: #f1f3f5;
}

.cui-list-item.cui-selected {
  color: #5c6bc0;
  font-weight: bold;
}

.cui-list-item.cui-disabled {
  opacity: .5 !important;
}

.cui-list-item.cui-disabled:hover {
  background: none;
}

.cui-list-item:last-child {
  border-bottom: none;
}

.cui-list-item-content-left .cui-icon {
  display: block;
}

.cui-list-item-content-right {
  margin-left: auto;
}

.cui-list.cui-xs .cui-list-item {
  padding: 8px;
  font-size: 12px;
}

.cui-list.cui-xs .cui-list-item-content-left {
  padding-right: 8px;
}

.cui-list.cui-xs .cui-list-item-content-right {
  padding-left: 8px;
}

.cui-list.cui-sm .cui-list-item {
  padding: 9px;
  font-size: 13px;
}

.cui-list.cui-sm .cui-list-item-content-left {
  padding-right: 9px;
}

.cui-list.cui-sm .cui-list-item-content-right {
  padding-left: 9px;
}

.cui-list.cui-lg .cui-list-item {
  padding: 11px;
  font-size: 16px;
}

.cui-list.cui-lg .cui-list-item-content-left {
  padding-right: 11px;
}

.cui-list.cui-lg .cui-list-item-content-right {
  padding-left: 11px;
}

.cui-list.cui-xl .cui-list-item {
  padding: 13px;
  font-size: 18px;
}

.cui-list.cui-xl .cui-list-item-content-left {
  padding-right: 13px;
}

.cui-list.cui-xl .cui-list-item-content-right {
  padding-left: 13px;
}

.cui-menu {
  vertical-align: middle;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  flex-direction: column;
  min-width: 140px;
  max-width: 180px;
  padding: 6px 0;
  display: inline-flex;
}

.cui-menu .cui-menu-divider {
  margin: 6px 0;
}

.cui-menu .cui-menu-heading {
  padding: 6px 12px;
}

.cui-menu .cui-menu-item {
  border-radius: 0;
}

.cui-menu .cui-menu-divider {
  border-bottom: 1px solid #c5cdd1;
}

.cui-menu .cui-menu-heading {
  color: #263238;
  font-size: 12px;
  font-weight: bold;
}

.cui-menu.cui-basic {
  border: none;
}

.cui-menu.cui-xs {
  min-width: 100px;
  max-width: 140px;
  padding: 5px 0;
}

.cui-menu.cui-xs .cui-menu-divider {
  margin: 5px 0;
}

.cui-menu.cui-xs .cui-menu-heading {
  padding: 5px 10px;
}

.cui-menu.cui-sm {
  min-width: 120px;
  max-width: 160px;
  padding: 5px 0;
}

.cui-menu.cui-sm .cui-menu-divider {
  margin: 5px 0;
}

.cui-menu.cui-sm .cui-menu-heading {
  padding: 5px 11px;
}

.cui-menu.cui-lg {
  min-width: 160px;
  max-width: 200px;
  padding: 7px 0;
}

.cui-menu.cui-lg .cui-menu-divider {
  margin: 7px 0;
}

.cui-menu.cui-lg .cui-menu-heading {
  padding: 7px 14px;
}

.cui-menu.cui-xl {
  min-width: 200px;
  max-width: 240px;
  padding: 8px 0;
}

.cui-menu.cui-xl .cui-menu-divider {
  margin: 8px 0;
}

.cui-menu.cui-xl .cui-menu-heading {
  padding: 8px 16px;
}

.cui-overlay-backdrop {
  z-index: 20;
  background-color: #10161ab3;
  position: fixed;
  inset: 0;
}

.fade-enter .cui-overlay-backdrop {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-overlay-backdrop {
  opacity: 1;
}

.fade-exit .cui-overlay-backdrop {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-exit-active .cui-overlay-backdrop {
  opacity: 0;
}

body.cui-overlay-open {
  overflow-y: hidden;
}

.cui-overlay-inline .cui-overlay-backdrop {
  position: absolute;
}

.cui-popover {
  z-index: 20;
  position: absolute;
  left: -9999px;
}

.cui-popover.fade-enter .cui-popover, .fade-enter .cui-popover {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-popover {
  opacity: 1;
}

.fade-exit .cui-popover {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-exit-active .cui-popover {
  opacity: 0;
}

.cui-popover[x-out-of-boundaries] {
  opacity: 0;
  visibility: hidden;
}

.cui-popover[x-placement^="top"] > .cui-popover-arrow {
  border-bottom: 1px solid #bbc3c7;
  border-left: 1px solid #bbc3c7;
  bottom: -7px;
}

.cui-popover[x-placement^="bottom"] > .cui-popover-arrow {
  border-top: 1px solid #bbc3c7;
  border-right: 1px solid #bbc3c7;
  top: -7px;
}

.cui-popover[x-placement^="right"] > .cui-popover-arrow {
  border-top: 1px solid #bbc3c7;
  border-left: 1px solid #bbc3c7;
  left: -7px;
}

.cui-popover[x-placement^="left"] > .cui-popover-arrow {
  border-bottom: 1px solid #bbc3c7;
  border-right: 1px solid #bbc3c7;
  right: -7px;
}

.cui-popover-content {
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  padding: 15px;
  display: inline-block;
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.cui-popover-arrow {
  pointer-events: none;
  background: #fff;
  width: 14px;
  height: 14px;
  position: absolute;
  transform: rotate(-45deg);
}

.cui-popover-backdrop {
  background: #ffffffa6;
}

.cui-popover-menu .cui-popover-content {
  border: none;
  padding: 0;
}

.cui-portal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.cui-query-list {
  outline: none;
}

.cui-query-list .cui-control-group {
  margin: 0 0 15px;
}

.cui-query-list .cui-control-group.cui-fluid > * {
  flex: none;
}

.cui-query-list .cui-control-group .cui-input {
  flex-grow: 1;
}

.cui-query-list .cui-list-item {
  border-bottom: none;
}

.cui-query-list-checkmark .cui-list-item {
  padding-left: 36px;
}

.cui-query-list-checkmark .cui-list-item-content-left {
  position: absolute;
  left: 10px;
}

.cui-query-list-checkmark .cui-selected .cui-icon-check {
  color: #5c6bc0;
}

.cui-query-list-checkmark .cui-xs .cui-list-item {
  padding-left: 30px;
}

.cui-query-list-checkmark .cui-xs .cui-list-item-content-left {
  left: 8px;
}

.cui-query-list-checkmark .cui-sm .cui-list-item {
  padding-left: 33px;
}

.cui-query-list-checkmark .cui-sm .cui-list-item-content-left {
  left: 9px;
}

.cui-query-list-checkmark .cui-lg .cui-list-item {
  padding-left: 42px;
}

.cui-query-list-checkmark .cui-lg .cui-list-item-content-left {
  left: 11px;
}

.cui-query-list-checkmark .cui-xl .cui-list-item {
  padding-left: 48px;
}

.cui-query-list-checkmark .cui-xl .cui-list-item-content-left {
  left: 13px;
}

.cui-query-list-empty, .cui-query-list-initial {
  background: none;
}

.cui-query-list-empty .cui-query-list-message, .cui-query-list-initial .cui-query-list-message {
  color: #607d8b;
  padding: 10px;
  font-size: 14px;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  width: 8px;
  height: 8px;
}

.cui-radio .cui-control-indicator {
  border: 1px solid #c5cdd1;
}

.cui-radio input:checked ~ .cui-control-indicator {
  border: 1px solid #5c6bc0;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  background: #5c6bc0;
}

.cui-radio .cui-control-indicator {
  border-radius: 50%;
}

.cui-radio input:checked ~ .cui-control-indicator {
  color: #fff;
  background: #fff;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cui-radio.cui-xs input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 6px;
}

.cui-radio.cui-sm input:checked ~ .cui-control-indicator:after {
  width: 7px;
  height: 7px;
}

.cui-radio.cui-lg input:checked ~ .cui-control-indicator:after {
  width: 9px;
  height: 9px;
}

.cui-radio.cui-xl input:checked ~ .cui-control-indicator:after {
  width: 10px;
  height: 10px;
}

.cui-radio.cui-primary .cui-control-indicator {
  border: 1px solid #5360ad;
}

.cui-radio.cui-primary input:checked ~ .cui-control-indicator {
  border: 1px solid #5c6bc0;
}

.cui-radio.cui-primary input:checked ~ .cui-control-indicator:after {
  background: #5c6bc0;
}

.cui-radio.cui-positive .cui-control-indicator {
  border: 1px solid #3d8c40;
}

.cui-radio.cui-positive input:checked ~ .cui-control-indicator {
  border: 1px solid #4caf50;
}

.cui-radio.cui-positive input:checked ~ .cui-control-indicator:after {
  background: #4caf50;
}

.cui-radio.cui-negative .cui-control-indicator {
  border: 1px solid #dc3c31;
}

.cui-radio.cui-negative input:checked ~ .cui-control-indicator {
  border: 1px solid #f44336;
}

.cui-radio.cui-negative input:checked ~ .cui-control-indicator:after {
  background: #f44336;
}

.cui-radio.cui-warning .cui-control-indicator {
  border: 1px solid #dd7000;
}

.cui-radio.cui-warning input:checked ~ .cui-control-indicator {
  border: 1px solid #f57c00;
}

.cui-radio.cui-warning input:checked ~ .cui-control-indicator:after {
  background: #f57c00;
}

.cui-radio-group .cui-radio {
  margin-right: 24px;
}

.cui-radio-group.cui-xs .cui-control {
  min-height: 12px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 12px;
}

.cui-radio-group.cui-xs .cui-control .cui-control-indicator {
  width: 12px;
  height: 12px;
}

.cui-radio-group.cui-xs .cui-control input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 6px;
}

.cui-radio-group.cui-xs .cui-radio {
  margin-right: 18px;
}

.cui-radio-group.cui-sm .cui-control {
  min-height: 14px;
  padding-left: 21px;
  font-size: 13px;
  line-height: 14px;
}

.cui-radio-group.cui-sm .cui-control .cui-control-indicator {
  width: 14px;
  height: 14px;
}

.cui-radio-group.cui-sm .cui-control input:checked ~ .cui-control-indicator:after {
  width: 7px;
  height: 7px;
}

.cui-radio-group.cui-sm .cui-radio {
  margin-right: 21px;
}

.cui-radio-group.cui-lg .cui-control {
  min-height: 18px;
  padding-left: 27px;
  font-size: 16px;
  line-height: 18px;
}

.cui-radio-group.cui-lg .cui-control .cui-control-indicator {
  width: 18px;
  height: 18px;
}

.cui-radio-group.cui-lg .cui-control input:checked ~ .cui-control-indicator:after {
  width: 9px;
  height: 9px;
}

.cui-radio-group.cui-lg .cui-radio {
  margin-right: 27px;
}

.cui-radio-group.cui-xl .cui-control {
  min-height: 20px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 20px;
}

.cui-radio-group.cui-xl .cui-control .cui-control-indicator {
  width: 20px;
  height: 20px;
}

.cui-radio-group.cui-xl .cui-control input:checked ~ .cui-control-indicator:after {
  width: 10px;
  height: 10px;
}

.cui-radio-group.cui-xl .cui-radio {
  margin-right: 30px;
}

.cui-select {
  vertical-align: middle;
  background: #fff;
  display: inline-flex;
  position: relative;
}

.cui-select select {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-select select:not(:first-child) {
  padding-left: 36px;
}

.cui-select select:not(:last-child) {
  padding-right: 36px;
}

.cui-select > .cui-button:first-child:not(input), .cui-select > .cui-spinner:first-child:not(input), .cui-select > .cui-icon:first-child:not(input), .cui-select > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-select > .cui-button:last-child, .cui-select > .cui-spinner:last-child, .cui-select > .cui-icon:last-child, .cui-select > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-select > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-select select {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-select select:hover {
  color: #546e7a;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #b0bec5;
}

.cui-select select:active, .cui-select select.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #b0bec5;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select select[disabled], .cui-select select .cui-disabled {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-basic select:hover {
  color: #546e7a;
  background: #f6f7f8;
  border: none;
}

.cui-select.cui-basic select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
}

.cui-select .cui-icon {
  color: #607d8b;
}

.cui-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  transition: background .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
}

.cui-select select::-ms-expand {
  display: none;
}

.cui-select .cui-icon {
  pointer-events: none;
}

.cui-select > .cui-button, .cui-select > .cui-spinner, .cui-select > .cui-icon, .cui-select > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-select > .cui-button:first-child, .cui-select > .cui-spinner:first-child, .cui-select > .cui-icon:first-child, .cui-select > .cui-tag:first-child {
  left: 0;
}

.cui-select > .cui-button:last-child, .cui-select > .cui-spinner:last-child, .cui-select > .cui-icon:last-child, .cui-select > .cui-tag:last-child {
  right: 0;
}

.cui-select.cui-disabled select, .cui-select.cui-disabled .cui-button, .cui-select.cui-disabled .cui-spinner, .cui-select.cui-disabled .cui-icon, .cui-select.cui-disabled .cui-tag {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-select.cui-xs select {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-select.cui-xs select:not(:first-child) {
  padding-left: 30px;
}

.cui-select.cui-xs select:not(:last-child) {
  padding-right: 30px;
}

.cui-select.cui-xs > .cui-button:first-child:not(input), .cui-select.cui-xs > .cui-spinner:first-child:not(input), .cui-select.cui-xs > .cui-icon:first-child:not(input), .cui-select.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-select.cui-xs > .cui-button:last-child, .cui-select.cui-xs > .cui-spinner:last-child, .cui-select.cui-xs > .cui-icon:last-child, .cui-select.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-select.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-select.cui-sm select {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-select.cui-sm select:not(:first-child) {
  padding-left: 33px;
}

.cui-select.cui-sm select:not(:last-child) {
  padding-right: 33px;
}

.cui-select.cui-sm > .cui-button:first-child:not(input), .cui-select.cui-sm > .cui-spinner:first-child:not(input), .cui-select.cui-sm > .cui-icon:first-child:not(input), .cui-select.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-select.cui-sm > .cui-button:last-child, .cui-select.cui-sm > .cui-spinner:last-child, .cui-select.cui-sm > .cui-icon:last-child, .cui-select.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-select.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-select.cui-lg select {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-select.cui-lg select:not(:first-child) {
  padding-left: 42px;
}

.cui-select.cui-lg select:not(:last-child) {
  padding-right: 42px;
}

.cui-select.cui-lg > .cui-button:first-child:not(input), .cui-select.cui-lg > .cui-spinner:first-child:not(input), .cui-select.cui-lg > .cui-icon:first-child:not(input), .cui-select.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-select.cui-lg > .cui-button:last-child, .cui-select.cui-lg > .cui-spinner:last-child, .cui-select.cui-lg > .cui-icon:last-child, .cui-select.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-select.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-select.cui-xl select {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-select.cui-xl select:not(:first-child) {
  padding-left: 48px;
}

.cui-select.cui-xl select:not(:last-child) {
  padding-right: 48px;
}

.cui-select.cui-xl > .cui-button:first-child:not(input), .cui-select.cui-xl > .cui-spinner:first-child:not(input), .cui-select.cui-xl > .cui-icon:first-child:not(input), .cui-select.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-select.cui-xl > .cui-button:last-child, .cui-select.cui-xl > .cui-spinner:last-child, .cui-select.cui-xl > .cui-icon:last-child, .cui-select.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-select.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-select.cui-primary select {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #5360ad;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-primary select:hover {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #4a569a;
}

.cui-select.cui-primary select:active, .cui-select.cui-primary select.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #404b86;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-primary select[disabled], .cui-select.cui-primary select .cui-disabled {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #5360ad;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-primary.cui-basic select:hover {
  color: #5c6bc0;
  background: #eff0f9;
  border: none;
}

.cui-select.cui-primary.cui-basic select:active {
  background: #dee1f2;
}

.cui-select.cui-primary .cui-icon {
  color: #5c6bc0;
}

.cui-select.cui-positive select {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #3d8c40;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-positive select:hover {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #357b38;
}

.cui-select.cui-positive select:active, .cui-select.cui-positive select.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #357b38;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-positive select[disabled], .cui-select.cui-positive select .cui-disabled {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #3d8c40;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-positive.cui-basic select:hover {
  color: #4caf50;
  background: #edf7ee;
  border: none;
}

.cui-select.cui-positive.cui-basic select:active {
  background: #dbefdc;
}

.cui-select.cui-positive .cui-icon {
  color: #4caf50;
}

.cui-select.cui-negative select {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dc3c31;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-negative select:hover {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #c3362b;
}

.cui-select.cui-negative select:active, .cui-select.cui-negative select.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #ab2f26;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-negative select[disabled], .cui-select.cui-negative select .cui-disabled {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dc3c31;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-negative.cui-basic select:hover {
  color: #f44336;
  background: #feeceb;
  border: none;
}

.cui-select.cui-negative.cui-basic select:active {
  background: #fdd9d7;
}

.cui-select.cui-negative .cui-icon {
  color: #f44336;
}

.cui-select.cui-warning select {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dd7000;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-warning select:hover {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #c46300;
}

.cui-select.cui-warning select:active, .cui-select.cui-warning select.cui-active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #ac5700;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-warning select[disabled], .cui-select.cui-warning select .cui-disabled {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dd7000;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-warning.cui-basic select:hover {
  color: #f57c00;
  background: #fef2e6;
  border: none;
}

.cui-select.cui-warning.cui-basic select:active {
  background: #fde5cc;
}

.cui-select.cui-warning .cui-icon {
  color: #f57c00;
}

.cui-select.cui-basic select {
  box-shadow: none;
  background: none;
  border: none;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cui-spinner {
  vertical-align: middle;
  opacity: 0;
  user-select: none;
  will-change: opacity;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease-in-out, visibility 0s ease-in-out .3s, background .3s ease-in-out;
  display: inline-flex;
  position: relative;
}

.cui-spinner .cui-spinner-icon {
  width: 20px;
  height: 20px;
}

.cui-spinner .cui-spinner-message {
  font-size: 14px;
}

.cui-spinner .cui-spinner-icon {
  border-top-color: #5c6bc0 !important;
}

.cui-spinner.cui-spinner-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.cui-spinner.cui-spinner-fill {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.cui-spinner.cui-spinner-bg {
  background: #ffffff80;
}

.cui-spinner.cui-xs .cui-spinner-icon, .cui-xs .cui-spinner .cui-spinner-icon {
  width: 15px;
  height: 15px;
}

.cui-spinner.cui-xs .cui-spinner-message, .cui-xs .cui-spinner .cui-spinner-message {
  font-size: 12px;
}

.cui-spinner.cui-sm .cui-spinner-icon, .cui-sm .cui-spinner .cui-spinner-icon {
  width: 17px;
  height: 17px;
}

.cui-spinner.cui-sm .cui-spinner-message, .cui-sm .cui-spinner .cui-spinner-message {
  font-size: 13px;
}

.cui-spinner.cui-lg .cui-spinner-icon, .cui-lg .cui-spinner .cui-spinner-icon {
  width: 22px;
  height: 22px;
}

.cui-spinner.cui-lg .cui-spinner-message, .cui-lg .cui-spinner .cui-spinner-message {
  font-size: 16px;
}

.cui-spinner.cui-xl .cui-spinner-icon, .cui-xl .cui-spinner .cui-spinner-icon {
  width: 25px;
  height: 25px;
}

.cui-spinner.cui-xl .cui-spinner-message, .cui-xl .cui-spinner .cui-spinner-message {
  font-size: 18px;
}

.cui-spinner.cui-primary .cui-spinner-icon, .cui-primary .cui-spinner .cui-spinner-icon {
  border-top-color: #5c6bc0 !important;
}

.cui-spinner.cui-positive .cui-spinner-icon, .cui-positive .cui-spinner .cui-spinner-icon {
  border-top-color: #4caf50 !important;
}

.cui-spinner.cui-negative .cui-spinner-icon, .cui-negative .cui-spinner .cui-spinner-icon {
  border-top-color: #f44336 !important;
}

.cui-spinner.cui-warning .cui-spinner-icon, .cui-warning .cui-spinner .cui-spinner-icon {
  border-top-color: #f57c00 !important;
}

.cui-spinner-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cui-spinner-icon {
  z-index: 1;
  border: 2px solid #c5cdd1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .5s linear infinite loading;
  border-top-color: red !important;
}

.cui-spinner-message {
  color: #607d8b;
  margin-top: 10px;
}

.cui-switch {
  padding-left: 40px;
}

.cui-switch .cui-control-indicator {
  width: 32px;
  height: 16px;
}

.cui-switch .cui-control-indicator:after {
  width: 16px;
  height: 16px;
}

.cui-switch input:checked ~ .cui-control-indicator:after {
  left: 16px;
}

.cui-switch .cui-control-indicator {
  background: #eceff1;
}

.cui-switch:hover .cui-control-indicator {
  background: #d4d7d9;
  border: none;
}

.cui-switch input:checked ~ .cui-control-indicator {
  background: #5c6bc0;
}

.cui-switch .cui-control-indicator {
  border: none;
  border-radius: 40px;
}

.cui-switch .cui-control-indicator:after {
  content: "";
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 40px;
  transition: left .2s cubic-bezier(.4, 1, .75, .9);
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.cui-switch:hover .cui-control-indicator {
  border: none;
}

.cui-switch.cui-xs {
  padding-left: 30px;
}

.cui-switch.cui-xs .cui-control-indicator {
  width: 24px;
  height: 12px;
}

.cui-switch.cui-xs .cui-control-indicator:after {
  width: 12px;
  height: 12px;
}

.cui-switch.cui-xs input:checked ~ .cui-control-indicator:after {
  left: 12px;
}

.cui-switch.cui-sm {
  padding-left: 35px;
}

.cui-switch.cui-sm .cui-control-indicator {
  width: 28px;
  height: 14px;
}

.cui-switch.cui-sm .cui-control-indicator:after {
  width: 14px;
  height: 14px;
}

.cui-switch.cui-sm input:checked ~ .cui-control-indicator:after {
  left: 14px;
}

.cui-switch.cui-lg {
  padding-left: 45px;
}

.cui-switch.cui-lg .cui-control-indicator {
  width: 36px;
  height: 18px;
}

.cui-switch.cui-lg .cui-control-indicator:after {
  width: 18px;
  height: 18px;
}

.cui-switch.cui-lg input:checked ~ .cui-control-indicator:after {
  left: 18px;
}

.cui-switch.cui-xl {
  padding-left: 50px;
}

.cui-switch.cui-xl .cui-control-indicator {
  width: 40px;
  height: 20px;
}

.cui-switch.cui-xl .cui-control-indicator:after {
  width: 20px;
  height: 20px;
}

.cui-switch.cui-xl input:checked ~ .cui-control-indicator:after {
  left: 20px;
}

.cui-switch.cui-primary .cui-control-indicator {
  background: #aeb5e0;
}

.cui-switch.cui-primary:hover .cui-control-indicator {
  background: #8d97d3;
  border: none;
}

.cui-switch.cui-primary input:checked ~ .cui-control-indicator {
  background: #5c6bc0;
}

.cui-switch.cui-positive .cui-control-indicator {
  background: #a6d7a8;
}

.cui-switch.cui-positive:hover .cui-control-indicator {
  background: #82c785;
  border: none;
}

.cui-switch.cui-positive input:checked ~ .cui-control-indicator {
  background: #4caf50;
}

.cui-switch.cui-negative .cui-control-indicator {
  background: #faa19b;
}

.cui-switch.cui-negative:hover .cui-control-indicator {
  background: #f77b72;
  border: none;
}

.cui-switch.cui-negative input:checked ~ .cui-control-indicator {
  background: #f44336;
}

.cui-switch.cui-warning .cui-control-indicator {
  background: #fabe80;
}

.cui-switch.cui-warning:hover .cui-control-indicator {
  background: #f8a34d;
  border: none;
}

.cui-switch.cui-warning input:checked ~ .cui-control-indicator {
  background: #f57c00;
}

.cui-tabs {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cui-tabs .cui-tabs-item {
  padding: 12px;
  font-size: 14px;
}

.cui-tabs.cui-tabs-bordered {
  border-bottom: 1px solid #c5cdd1;
}

.cui-tabs.cui-align-left {
  justify-content: flex-start;
}

.cui-tabs.cui-align-right {
  justify-content: flex-end;
}

.cui-tabs.cui-xs .cui-tabs-item {
  padding: 10px;
  font-size: 12px;
}

.cui-tabs.cui-sm .cui-tabs-item {
  padding: 11px;
  font-size: 13px;
}

.cui-tabs.cui-lg .cui-tabs-item {
  padding: 14px;
  font-size: 16px;
}

.cui-tabs.cui-xl .cui-tabs-item {
  padding: 16px;
  font-size: 18px;
}

.cui-tabs-item {
  color: #90a4ae;
  cursor: pointer;
  border-bottom: 2px solid #0000;
  align-items: center;
  font-weight: bold;
  transition: color .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9);
  display: flex;
  position: relative;
}

.cui-tabs-item .cui-icon {
  color: #90a4ae;
}

.cui-tabs-item:hover, .cui-tabs-item:hover .cui-icon {
  color: #607d8b;
}

.cui-tabs-item.cui-active {
  color: #5c6bc0;
  border-bottom: 2px solid #5c6bc0;
}

.cui-tabs-item.cui-active .cui-icon {
  color: #5c6bc0;
}

.cui-tabs-item.cui-loading {
  pointer-events: none;
  visibility: hidden;
}

.cui-table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
}

.cui-table tbody {
  width: 100%;
}

.cui-table th, .cui-table td {
  text-align: left;
  border-bottom: 1px solid #c5cdd1;
  padding: 10px;
  font-size: 14px;
}

.cui-table:not(.cui-table-bordered) tr:last-child td, .cui-table:not(.cui-table-bordered) tr:last-child th {
  border-bottom: none;
}

.cui-table.cui-table-bordered td, .cui-table.cui-table-bordered th {
  border: 1px solid #c5cdd1;
}

.cui-table.cui-table-striped tr:nth-of-type(odd) {
  background: #f1f3f5;
}

.cui-table.cui-table-interactive tr {
  cursor: pointer;
}

.cui-table.cui-table-interactive tr:hover {
  background: #f1f3f5;
}

.cui-table.cui-table-interactive tr:active {
  background: #eceff1;
}

.cui-tag {
  color: #607d8b;
  vertical-align: middle;
  background: #f1f3f5;
  border: 1px solid #d4dce0;
  border-radius: 3px;
  align-items: center;
  margin-right: 8px;
  padding: 2px 8px;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.cui-tag.cui-tag-removable .cui-icon {
  margin-left: 8px;
}

.cui-tag.cui-rounded {
  border-radius: 30px;
}

.cui-tag.cui-primary .cui-icon, .cui-tag.cui-positive .cui-icon, .cui-tag.cui-negative .cui-icon, .cui-tag.cui-warning .cui-icon {
  color: #eceff1;
}

.cui-tag.cui-primary .cui-icon:hover, .cui-tag.cui-positive .cui-icon:hover, .cui-tag.cui-negative .cui-icon:hover, .cui-tag.cui-warning .cui-icon:hover {
  color: #fff;
}

.cui-tag.cui-xs, .cui-xs .cui-tag {
  margin-right: 6px;
  padding: 1px 6px;
  font-size: 12px;
}

.cui-tag.cui-xs.cui-tag-removable .cui-icon, .cui-xs .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 6px;
}

.cui-tag.cui-sm, .cui-sm .cui-tag {
  margin-right: 7px;
  padding: 1px 7px;
  font-size: 13px;
}

.cui-tag.cui-sm.cui-tag-removable .cui-icon, .cui-sm .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 7px;
}

.cui-tag.cui-lg, .cui-lg .cui-tag {
  margin-right: 9px;
  padding: 2px 9px;
  font-size: 16px;
}

.cui-tag.cui-lg.cui-tag-removable .cui-icon, .cui-lg .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 9px;
}

.cui-tag.cui-xl, .cui-xl .cui-tag {
  margin-right: 10px;
  padding: 2px 10px;
  font-size: 18px;
}

.cui-tag.cui-xl.cui-tag-removable .cui-icon, .cui-xl .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 10px;
}

.cui-tag.cui-primary {
  color: #fff;
  background: #5c6bc0;
  border: 1px solid #4a569a;
}

.cui-tag.cui-positive {
  color: #fff;
  background: #4caf50;
  border: 1px solid #3d8c40;
}

.cui-tag.cui-negative {
  color: #fff;
  background: #f44336;
  border: 1px solid #c3362b;
}

.cui-tag.cui-warning {
  color: #fff;
  background: #f57c00;
  border: 1px solid #c46300;
}

.cui-tag-input {
  color: #37474f;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  display: inline-block;
  position: relative;
}

.cui-tag-input:focus, .cui-tag-input.cui-active {
  border: 1px solid #5360ad;
}

.cui-tag-input .cui-tag-input-values {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-tag-input .cui-tag-input-values:not(:first-child) {
  padding-left: 36px;
}

.cui-tag-input .cui-tag-input-values:not(:last-child) {
  padding-right: 36px;
}

.cui-tag-input > .cui-button:first-child:not(input), .cui-tag-input > .cui-spinner:first-child:not(input), .cui-tag-input > .cui-icon:first-child:not(input), .cui-tag-input > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-tag-input > .cui-button:last-child, .cui-tag-input > .cui-spinner:last-child, .cui-tag-input > .cui-icon:last-child, .cui-tag-input > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-tag-input > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-tag-input > .cui-icon {
  top: 9px;
}

.cui-tag-input .cui-tag-input-values {
  padding-top: 5px;
}

.cui-tag-input input {
  margin-top: 1px;
  margin-bottom: 6px;
}

.cui-tag-input .cui-tag {
  margin: 0 6px 4px 0;
}

.cui-tag-input:hover {
  cursor: text;
}

.cui-tag-input .cui-tag-input-values {
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  display: flex;
  line-height: inherit !important;
  height: auto !important;
  min-height: auto !important;
}

.cui-tag-input input {
  color: #37474f;
  vertical-align: middle;
  background: none;
  border: none;
  outline: none;
  flex: auto;
  width: 100px;
}

.cui-tag-input input::placeholder {
  color: #b0bec5;
}

.cui-tag-input input::-ms-clear {
  display: none;
}

.cui-tag-input > .cui-icon {
  z-index: 1;
  margin: 0;
  position: absolute;
}

.cui-tag-input > .cui-icon:first-child {
  left: 0;
}

.cui-tag-input > .cui-icon:last-child {
  right: 0;
}

.cui-tag-input.cui-disabled input, .cui-tag-input.cui-disabled .cui-button, .cui-tag-input.cui-disabled .cui-icon {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-tag-input.cui-xs .cui-tag-input-values {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-tag-input.cui-xs .cui-tag-input-values:not(:first-child) {
  padding-left: 30px;
}

.cui-tag-input.cui-xs .cui-tag-input-values:not(:last-child) {
  padding-right: 30px;
}

.cui-tag-input.cui-xs > .cui-button:first-child:not(input), .cui-tag-input.cui-xs > .cui-spinner:first-child:not(input), .cui-tag-input.cui-xs > .cui-icon:first-child:not(input), .cui-tag-input.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-tag-input.cui-xs > .cui-button:last-child, .cui-tag-input.cui-xs > .cui-spinner:last-child, .cui-tag-input.cui-xs > .cui-icon:last-child, .cui-tag-input.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-tag-input.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-tag-input.cui-xs > .cui-icon {
  top: 7px;
}

.cui-tag-input.cui-xs .cui-tag-input-values {
  padding-top: 4px;
}

.cui-tag-input.cui-xs input {
  margin-top: 1px;
  margin-bottom: 5px;
}

.cui-tag-input.cui-xs .cui-tag {
  margin: 0 5px 3px 0;
}

.cui-tag-input.cui-sm .cui-tag-input-values {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-tag-input.cui-sm .cui-tag-input-values:not(:first-child) {
  padding-left: 33px;
}

.cui-tag-input.cui-sm .cui-tag-input-values:not(:last-child) {
  padding-right: 33px;
}

.cui-tag-input.cui-sm > .cui-button:first-child:not(input), .cui-tag-input.cui-sm > .cui-spinner:first-child:not(input), .cui-tag-input.cui-sm > .cui-icon:first-child:not(input), .cui-tag-input.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-tag-input.cui-sm > .cui-button:last-child, .cui-tag-input.cui-sm > .cui-spinner:last-child, .cui-tag-input.cui-sm > .cui-icon:last-child, .cui-tag-input.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-tag-input.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-tag-input.cui-sm > .cui-icon {
  top: 8px;
}

.cui-tag-input.cui-sm .cui-tag-input-values {
  padding-top: 4px;
}

.cui-tag-input.cui-sm input {
  margin-top: 1px;
  margin-bottom: 5px;
}

.cui-tag-input.cui-sm .cui-tag {
  margin: 0 5px 3px 0;
}

.cui-tag-input.cui-lg .cui-tag-input-values {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-tag-input.cui-lg .cui-tag-input-values:not(:first-child) {
  padding-left: 42px;
}

.cui-tag-input.cui-lg .cui-tag-input-values:not(:last-child) {
  padding-right: 42px;
}

.cui-tag-input.cui-lg > .cui-button:first-child:not(input), .cui-tag-input.cui-lg > .cui-spinner:first-child:not(input), .cui-tag-input.cui-lg > .cui-icon:first-child:not(input), .cui-tag-input.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-tag-input.cui-lg > .cui-button:last-child, .cui-tag-input.cui-lg > .cui-spinner:last-child, .cui-tag-input.cui-lg > .cui-icon:last-child, .cui-tag-input.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-tag-input.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-tag-input.cui-lg > .cui-icon {
  top: 11px;
}

.cui-tag-input.cui-lg .cui-tag-input-values {
  padding-top: 6px;
}

.cui-tag-input.cui-lg input {
  margin-top: 1px;
  margin-bottom: 7px;
}

.cui-tag-input.cui-lg .cui-tag {
  margin: 0 7px 5px 0;
}

.cui-tag-input.cui-xl .cui-tag-input-values {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-tag-input.cui-xl .cui-tag-input-values:not(:first-child) {
  padding-left: 48px;
}

.cui-tag-input.cui-xl .cui-tag-input-values:not(:last-child) {
  padding-right: 48px;
}

.cui-tag-input.cui-xl > .cui-button:first-child:not(input), .cui-tag-input.cui-xl > .cui-spinner:first-child:not(input), .cui-tag-input.cui-xl > .cui-icon:first-child:not(input), .cui-tag-input.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-tag-input.cui-xl > .cui-button:last-child, .cui-tag-input.cui-xl > .cui-spinner:last-child, .cui-tag-input.cui-xl > .cui-icon:last-child, .cui-tag-input.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-tag-input.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-tag-input.cui-xl > .cui-icon {
  top: 13px;
}

.cui-tag-input.cui-xl .cui-tag-input-values {
  padding-top: 7px;
}

.cui-tag-input.cui-xl input {
  margin-top: 1px;
  margin-bottom: 8px;
}

.cui-tag-input.cui-xl .cui-tag {
  margin: 0 8px 6px 0;
}

.cui-tag-input.cui-primary {
  border: 1px solid #5360ad;
}

.cui-tag-input.cui-primary:focus, .cui-tag-input.cui-primary.cui-active {
  border: 1px solid #4a569a;
}

.cui-tag-input.cui-positive {
  border: 1px solid #3d8c40;
}

.cui-tag-input.cui-positive:focus, .cui-tag-input.cui-positive.cui-active {
  border: 1px solid #357b38;
}

.cui-tag-input.cui-negative {
  border: 1px solid #dc3c31;
}

.cui-tag-input.cui-negative:focus, .cui-tag-input.cui-negative.cui-active {
  border: 1px solid #c3362b;
}

.cui-tag-input.cui-warning {
  border: 1px solid #dd7000;
}

.cui-tag-input.cui-warning:focus, .cui-tag-input.cui-warning.cui-active {
  border: 1px solid #c46300;
}

.cui-text-area {
  position: relative;
}

.cui-text-area textarea {
  resize: both;
  padding-top: 12px;
  padding-bottom: 12px;
  height: initial !important;
  line-height: initial !important;
}

.cui-text-area.cui-xs textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cui-text-area.cui-sm textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}

.cui-text-area.cui-lg textarea {
  padding-top: 14px;
  padding-bottom: 14px;
}

.cui-text-area.cui-xl textarea {
  padding-top: 16px;
  padding-bottom: 16px;
}

.cui-toaster {
  z-index: 20;
  pointer-events: none;
  flex-direction: column;
  padding: 20px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

.cui-toaster.cui-toaster-inline {
  position: absolute;
}

.cui-toaster.cui-toaster-top-start, .cui-toaster.cui-toaster-bottom-start {
  align-items: flex-start;
}

.cui-toaster.cui-toaster-top, .cui-toaster.cui-toaster-bottom {
  align-items: center;
}

.cui-toaster.cui-toaster-top-end, .cui-toaster.cui-toaster-bottom-end {
  align-items: flex-end;
}

.cui-toaster.cui-toaster-top, .cui-toaster.cui-toaster-top-start, .cui-toaster.cui-toaster-top-end {
  top: 0;
  bottom: auto;
}

.cui-toaster.cui-toaster-bottom, .cui-toaster.cui-toaster-bottom-start, .cui-toaster.cui-toaster-bottom-end {
  top: auto;
  bottom: 0;
}

.cui-toast {
  pointer-events: all;
  color: #607d8b;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  outline: none;
  align-items: center;
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 12px;
  transition: color .3s ease-in;
  display: flex;
  position: relative;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast .cui-toast-message {
  padding-right: 12px;
  font-size: 14px;
}

.cui-toast > .cui-icon:first-child {
  margin-right: 12px;
}

.cui-toast.cui-primary > .cui-icon, .cui-toast.cui-positive > .cui-icon, .cui-toast.cui-negative > .cui-icon, .cui-toast.cui-warning > .cui-icon {
  color: #fff;
}

.cui-toast.cui-primary > .cui-icon-x, .cui-toast.cui-positive > .cui-icon-x, .cui-toast.cui-negative > .cui-icon-x, .cui-toast.cui-warning > .cui-icon-x {
  color: #eceff1;
}

.cui-toast.cui-primary > .cui-icon-x:hover, .cui-toast.cui-positive > .cui-icon-x:hover, .cui-toast.cui-negative > .cui-icon-x:hover, .cui-toast.cui-warning > .cui-icon-x:hover {
  color: #fff;
}

.cui-toast .cui-toast-message {
  flex: auto;
}

.cui-toast.cui-xs {
  padding: 10px;
}

.cui-toast.cui-xs .cui-toast-message {
  padding-right: 10px;
  font-size: 12px;
}

.cui-toast.cui-xs > .cui-icon:first-child {
  margin-right: 10px;
}

.cui-toast.cui-sm {
  padding: 11px;
}

.cui-toast.cui-sm .cui-toast-message {
  padding-right: 11px;
  font-size: 13px;
}

.cui-toast.cui-sm > .cui-icon:first-child {
  margin-right: 11px;
}

.cui-toast.cui-lg {
  padding: 14px;
}

.cui-toast.cui-lg .cui-toast-message {
  padding-right: 14px;
  font-size: 16px;
}

.cui-toast.cui-lg > .cui-icon:first-child {
  margin-right: 14px;
}

.cui-toast.cui-xl {
  padding: 16px;
}

.cui-toast.cui-xl .cui-toast-message {
  padding-right: 16px;
  font-size: 18px;
}

.cui-toast.cui-xl > .cui-icon:first-child {
  margin-right: 16px;
}

.cui-toast.cui-primary {
  color: #fff;
  background: #5c6bc0;
  border: 1px solid #5360ad;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-positive {
  color: #fff;
  background: #4caf50;
  border: 1px solid #3d8c40;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-negative {
  color: #fff;
  background: #f44336;
  border: 1px solid #dc3c31;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-warning {
  color: #fff;
  background: #f57c00;
  border: 1px solid #dd7000;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-tooltip .cui-popover-arrow {
  width: 10px;
  height: 10px;
}

.cui-tooltip .cui-popover-content {
  padding: 8px;
  font-size: 13px;
}

.cui-tooltip[x-placement^="top"] .cui-popover-arrow {
  bottom: -5px;
}

.cui-tooltip[x-placement^="bottom"] .cui-popover-arrow {
  top: -5px;
}

.cui-tooltip[x-placement^="right"] .cui-popover-arrow {
  left: -5px;
}

.cui-tooltip[x-placement^="left"] .cui-popover-arrow {
  right: -5px;
}

.cui-tooltip .cui-popover-content {
  color: #37474f;
  width: auto;
  min-width: auto;
}

.cui-tooltip.cui-xs .cui-popover-arrow {
  width: 8px;
  height: 8px;
}

.cui-tooltip.cui-xs .cui-popover-content {
  padding: 6px;
  font-size: 11px;
}

.cui-tooltip.cui-xs[x-placement^="top"] .cui-popover-arrow {
  bottom: -4px;
}

.cui-tooltip.cui-xs[x-placement^="bottom"] .cui-popover-arrow {
  top: -4px;
}

.cui-tooltip.cui-xs[x-placement^="right"] .cui-popover-arrow {
  left: -4px;
}

.cui-tooltip.cui-xs[x-placement^="left"] .cui-popover-arrow {
  right: -4px;
}

.cui-tooltip.cui-sm .cui-popover-arrow {
  width: 9px;
  height: 9px;
}

.cui-tooltip.cui-sm .cui-popover-content {
  padding: 7px;
  font-size: 12px;
}

.cui-tooltip.cui-sm[x-placement^="top"] .cui-popover-arrow {
  bottom: -4px;
}

.cui-tooltip.cui-sm[x-placement^="bottom"] .cui-popover-arrow {
  top: -4px;
}

.cui-tooltip.cui-sm[x-placement^="right"] .cui-popover-arrow {
  left: -4px;
}

.cui-tooltip.cui-sm[x-placement^="left"] .cui-popover-arrow {
  right: -4px;
}

.cui-tooltip.cui-lg .cui-popover-arrow {
  width: 11px;
  height: 11px;
}

.cui-tooltip.cui-lg .cui-popover-content {
  padding: 9px;
  font-size: 15px;
}

.cui-tooltip.cui-lg[x-placement^="top"] .cui-popover-arrow {
  bottom: -5px;
}

.cui-tooltip.cui-lg[x-placement^="bottom"] .cui-popover-arrow {
  top: -5px;
}

.cui-tooltip.cui-lg[x-placement^="right"] .cui-popover-arrow {
  left: -5px;
}

.cui-tooltip.cui-lg[x-placement^="left"] .cui-popover-arrow {
  right: -5px;
}

.cui-tooltip.cui-xl .cui-popover-arrow {
  width: 13px;
  height: 13px;
}

.cui-tooltip.cui-xl .cui-popover-content {
  padding: 10px;
  font-size: 17px;
}

.cui-tooltip.cui-xl[x-placement^="top"] .cui-popover-arrow {
  bottom: -6px;
}

.cui-tooltip.cui-xl[x-placement^="bottom"] .cui-popover-arrow {
  top: -6px;
}

.cui-tooltip.cui-xl[x-placement^="right"] .cui-popover-arrow {
  left: -6px;
}

.cui-tooltip.cui-xl[x-placement^="left"] .cui-popover-arrow {
  right: -6px;
}

.cui-tree {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cui-tree-node-list {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}

.cui-tree-node-content {
  align-items: center;
  padding: 5px;
  display: flex;
}

.cui-tree-node-content:hover {
  background-color: #f6f7f8;
}

.cui-tree-node-caret, .cui-tree-node-caret-none {
  text-align: center;
  position: relative;
}

.cui-tree-node-caret-none {
  opacity: 0;
  visibility: hidden;
}

.cui-tree-node-caret {
  color: #37474f;
  transform-origin: center;
  cursor: pointer;
  margin-right: 5px;
  transition: transform .4s cubic-bezier(.4, 1, .75, .9);
  display: inline-block;
}

.cui-tree-node-caret.cui-tree-node-caret-open {
  transform-origin: center;
  transform: rotate(90deg);
}

.cui-tree-node-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  user-select: none;
  flex: auto;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.cui-tree-node-label span {
  display: inline;
}

.cui-tree-node-content-right {
  margin-left: 5px;
  display: flex;
}

.cui-tree-node-content-left {
  margin-right: 5px;
  display: flex;
}

.cui-tree-node.cui-tree-node-selected > .cui-tree-node-content {
  background: #eceff1;
}

.tabulator {
  text-align: left;
  background-color: #888;
  border: 1px solid #999;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.tabulator[tabulator-layout="fitDataFill"] .tabulator-tableHolder .tabulator-table {
  min-width: 100%;
}

.tabulator[tabulator-layout="fitDataTable"] {
  display: inline-block;
}

.tabulator.tabulator-block-select {
  user-select: none;
}

.tabulator .tabulator-header {
  box-sizing: border-box;
  color: #555;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background-color: #e6e6e6;
  border-bottom: 1px solid #999;
  width: 100%;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-col {
  box-sizing: border-box;
  text-align: left;
  vertical-align: bottom;
  background: #e6e6e6;
  border-right: 1px solid #aaa;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  pointer-events: none;
  background: #cdcdcd;
  border: 1px solid #999;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  padding: 4px;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button {
  padding: 0 8px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button:hover {
  cursor: pointer;
  opacity: .6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title-holder {
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
  width: 100%;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #999;
  width: 100%;
  padding: 1px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-header-menu-button + .tabulator-title-editor {
  width: calc(100% - 22px);
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-bottom: 6px solid #bbb;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  border-top: 1px solid #aaa;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-top: 2px;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 25px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #cdcdcd;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter {
  color: #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter {
  color: #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter {
  color: #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  color: #666;
  border-top: 6px solid #666;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical .tabulator-col-content .tabulator-col-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-title {
  padding-top: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip .tabulator-col-title {
  padding-bottom: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-sorter {
  justify-content: center;
  inset: 4px 0 auto;
}

.tabulator .tabulator-header .tabulator-frozen {
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #aaa;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #aaa;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  min-width: 600%;
  overflow: hidden;
  background: #f3f3f3 !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: #f3f3f3 !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 600%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: relative;
  overflow: auto;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  display: flex;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-width: 100%;
  min-height: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  white-space: nowrap;
  color: #333;
  background-color: #fff;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: bold;
  background: #e2e2e2 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #aaa;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #aaa;
}

.tabulator .tabulator-footer {
  text-align: right;
  color: #555;
  white-space: nowrap;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background-color: #e6e6e6;
  border-top: 1px solid #999;
  padding: 5px 10px;
  font-weight: bold;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  text-align: left;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  width: calc(100% + 20px);
  margin: -5px -10px 5px;
  overflow: hidden;
  background: #f3f3f3 !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: #f3f3f3 !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  border-bottom: none;
  margin-bottom: -5px;
}

.tabulator .tabulator-footer .tabulator-paginator {
  color: #555;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page-size {
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 0 5px;
  padding: 2px 5px;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  background: #fff3;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 0 2px;
  padding: 2px 5px;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #d00;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: .5;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  color: #fff;
  background: #0003;
}

.tabulator .tabulator-col-resize-handle {
  width: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-loader {
  z-index: 100;
  text-align: center;
  background: #0006;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  color: #000;
  border: 4px solid #333;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  color: #590000;
  border: 4px solid #d00;
}

.tabulator-row {
  box-sizing: border-box;
  background-color: #fff;
  min-height: 22px;
  position: relative;
}

.tabulator-row.tabulator-row-even {
  background-color: #efefef;
}

.tabulator-row.tabulator-selectable:hover {
  cursor: pointer;
  background-color: #bbb;
}

.tabulator-row.tabulator-selected {
  background-color: #9abcea;
}

.tabulator-row.tabulator-selected:hover {
  cursor: pointer;
  background-color: #769bcc;
}

.tabulator-row.tabulator-row-moving {
  background: #fff;
  border: 1px solid #000;
}

.tabulator-row.tabulator-moving {
  pointer-events: none;
  z-index: 15;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  position: absolute;
}

.tabulator-row .tabulator-row-resize-handle {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  background-color: inherit;
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #aaa;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #aaa;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 5px;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  box-sizing: border-box;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: 1px solid #aaa;
  padding: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  outline: none;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input, .tabulator-row .tabulator-cell.tabulator-editing select {
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #d00;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input, .tabulator-row .tabulator-cell.tabulator-validation-fail select {
  color: #d00;
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  background: #666;
  width: 100%;
  height: 3px;
  margin-top: 2px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  vertical-align: middle;
  border-bottom: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-bottom-left-radius: 1px;
  width: 7px;
  height: 9px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  vertical-align: middle;
  background: #0000001a;
  border: 1px solid #333;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  background: none;
  width: 1px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  background: #333;
  width: 7px;
  height: 1px;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  background: #333;
  width: 1px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  background: #333;
  width: 7px;
  height: 1px;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  color: #fff;
  background: #666;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 1.1em;
  font-weight: bold;
  display: inline-flex;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: .7;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open, .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  border-radius: 14px;
  width: 14px;
  height: 14px;
  display: inline-block;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  border-right: 1px solid #aaa;
  min-width: 100%;
  padding: 5px 5px 5px 10px;
  font-weight: bold;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #666;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #666;
  border-right: 0;
  width: 0;
  height: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-row.tabulator-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-menu {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  background: #fff;
  border: 1px solid #aaa;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
  box-shadow: 0 0 5px #0003;
}

.tabulator-menu .tabulator-menu-item {
  box-sizing: border-box;
  user-select: none;
  padding: 5px 10px;
  position: relative;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: .5;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
  background: #efefef;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu {
  padding-right: 25px;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu:after {
  content: "";
  vertical-align: top;
  border: 1px solid #aaa;
  border-width: 1px 1px 0 0;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: calc(5px + .4em);
  right: 10px;
  transform: rotate(45deg);
}

.tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid #aaa;
}

.tabulator-edit-select-list {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  background: #fff;
  border: 1px solid #aaa;
  max-height: 200px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  color: #333;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active.focused {
  outline: 1px solid #ffffff80;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.focused {
  outline: 1px solid #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-notice {
  color: #333;
  text-align: center;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  color: #333;
  border-bottom: 1px solid #aaa;
  padding: 6px 4px 4px;
  font-weight: bold;
}

.tabulator.tabulator-ltr {
  direction: ltr;
}

.tabulator.tabulator-rtl {
  text-align: initial;
  direction: rtl;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col {
  text-align: initial;
  border-left: 1px solid #aaa;
  border-right: initial;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  margin-right: initial;
  margin-left: -1px;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-left: 25px;
  padding-right: 0;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  left: 8px;
  right: initial;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell {
  border-right: initial;
  border-left: 1px solid #aaa;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  margin-right: initial;
  border-bottom-left-radius: initial;
  border-left: initial;
  border-right: 2px solid #aaa;
  border-bottom-right-radius: 1px;
  margin-left: 5px;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-control {
  margin-right: initial;
  margin-left: 5px;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle {
  position: absolute;
  left: 0;
  right: auto;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle.prev {
  left: auto;
  right: 0;
}

.tabulator-print-fullscreen {
  z-index: 10000;
  position: absolute;
  inset: 0;
}

body.tabulator-print-fullscreen-hide > :not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;
}

.tabulator-print-table .tabulator-data-tree-branch {
  vertical-align: middle;
  border-bottom: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-bottom-left-radius: 1px;
  width: 7px;
  height: 9px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group {
  box-sizing: border-box;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  border-right: 1px solid #aaa;
  min-width: 100%;
  padding: 5px 5px 5px 10px;
  font-weight: bold;
}

.tabulator-print-table .tabulator-print-table-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #666;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-1 td {
  padding-left: 30px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-2 td {
  padding-left: 50px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-3 td {
  padding-left: 70px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-4 td {
  padding-left: 90px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-5 td {
  padding-left: 110px !important;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-arrow {
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #666;
  border-right: 0;
  width: 0;
  height: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-print-table .tabulator-data-tree-control {
  vertical-align: middle;
  background: #0000001a;
  border: 1px solid #333;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-print-table .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  background: none;
  width: 1px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  background: #333;
  width: 7px;
  height: 1px;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  background: #333;
  width: 1px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  background: #333;
  width: 7px;
  height: 1px;
  position: absolute;
  top: 3px;
  left: -3px;
}

@font-face {
  font-family: GraphikRegular;
  src: url("Graphik-Regular-App.399a923c.ttf") format("truetype");
}

@font-face {
  font-family: GraphikBold;
  src: url("Graphik-Bold-App.26364d44.ttf") format("truetype");
}

@font-face {
  font-family: GraphikThin;
  src: url("Graphik-Thin-App.f5ff7bd8.ttf") format("truetype");
}

pre {
  border: initial;
}

.guestIcon {
  background-image: url("guestIcon.7407466e.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: content-box;
  width: 16px;
  height: 16px;
  display: inline-block;
}

#filmAZ {
  color: #000;
  background: #fff;
  font-family: GraphikRegular;
}

#filmAZ .header {
  height: 15px;
}

#filmAZ .section {
  color: #4d5980;
  text-align: right;
  padding-right: 10px;
  font-family: GraphikThin;
}

#filmAZ .section .Regular {
  color: #bfcc4d;
}

#filmAZ .premiere {
  float: right;
  padding-right: 10px;
}

#filmAZ .premiere:before {
  content: "{";
}

#filmAZ .premiere:after {
  content: "}";
}

#filmAZ .pretitle {
  display: inline-block;
}

#filmAZ .title {
  font-family: GraphikBold;
  font-size: 1.5rem;
  display: inline-block;
}

#filmAZ .posttitle {
  display: inline-block;
}

#filmAZ .subtitle {
  font-family: GraphikBold;
  font-size: 1rem;
}

#filmAZ .directors {
  font-family: GraphikRegular;
}

#filmAZ .countriesLine {
  font-family: GraphikBold;
}

#filmAZ .countriesLine div, #filmAZ .countries, #filmAZ .year, #filmAZ .length {
  display: inline-block;
}

#filmAZ .comma {
  padding: 0 4px 0 0;
}

#filmAZ .text {
  padding-top: 1rem;
}

#filmAZ .shows {
  padding-top: .5rem;
  font-family: Arial;
}

#filmAZ .shows span {
  display: inline-block;
}

#filmAZ .shows .weekday {
  text-transform: uppercase;
  width: 25px;
}

#filmAZ .shows .day {
  width: 20px;
  font-weight: bold;
}

#filmAZ .shows .time {
  width: 50px;
}

#filmAZ .shows .location {
  font-weight: bold;
}

#filmAZ .shows .postTitle {
  float: right;
  color: #ff4066;
}

#filmAZ .label {
  float: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: content-box;
  width: 50px;
  height: 43px;
  margin-right: 10px;
  display: block;
}

#filmAZ .label.IDFA_Bertha_Fund {
  background-image: url("bertha-fund.6c7414f3.png");
}

#filmAZ .label.IDFA_Forum {
  background-image: url("forum.a0b24a4c.png");
}

#filmAZ .label.IDFAcademy_Results {
  background-image: url("academy.1da5a0a6.png");
}

#filmAZ .sectionNames {
  padding: 3px;
  display: inline-block;
}

#filmAZ pre {
  margin-bottom: 0;
  font-family: Graphik Regular;
}

#showBlock {
  color: #000;
  background: #fff;
  width: 100%;
  height: 200px;
  font-family: GraphikRegular;
}

#showBlock .location {
  float: left;
  border-right: 1px solid #000;
  width: 300px;
  height: 200px;
  font-size: 3rem;
}

#showBlock .block {
  border: 1px solid #000;
  height: 200px;
  margin-right: 100px;
}

#showBlock .block .header {
  color: #fff;
  vertical-align: middle;
  background-color: #4d5980;
  height: 15px;
  padding-left: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: .6rem;
  font-weight: bold;
  line-height: 15px;
}

#showBlock .block .section {
  color: #4d5980;
  font-family: GraphikThin;
  font-size: .6rem;
}

#showBlock .block .section .Regular {
  color: #bfcc4d;
}

#showBlock .block .premiere {
  float: right;
  padding-right: 10px;
}

#showBlock .block .pretitle {
  color: #ff4066;
  display: inline-block;
}

#showBlock .block .title {
  font-family: GraphikBold;
  font-size: 1.5rem;
  display: inline-block;
}

#showBlock .block .posttitle {
  color: #ff4066;
  display: inline-block;
}

#showBlock .block .subtitle {
  font-family: GraphikBold;
  font-size: 1.7rem;
}

#showBlock .block .directors {
  font-family: GraphikRegular;
}

#showBlock .block .countriesLine {
  font-family: GraphikBold;
}

#showBlock .block .countries, #showBlock .block .year, #showBlock .block .length {
  display: inline-block;
}

#showBlock .block .comma {
  padding: 0 4px 0 0;
}

#showBlock .block .text {
  padding-top: .5rem;
  font-size: .6rem;
}

.blockday {
  border-top: 2px solid #000;
  position: relative;
}

.blockday.singleblock {
  overflow: initial;
}

.blockday.singleblock .block {
  position: relative !important;
  left: 0 !important;
}

.blockday .debug {
  font-size: .5rem;
  font-weight: initial;
}

.blockday .timeScale {
  white-space: nowrap;
  margin-left: 100px;
}

.blockday .timeScale .hour {
  border-left: 2px solid #000;
  display: inline-block;
}

.blockday .timeScaleLines {
  z-index: 2;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100px;
  overflow: hidden;
}

.blockday .timeScaleLines .line {
  border-left: 1px solid #0000001a;
  height: 100%;
  display: inline-block;
}

.blockday .location {
  color: #000;
  background-color: #fff;
  border-top: 1px solid #000;
  position: relative;
}

.blockday .location .location_title {
  border-right: 1px solid #000;
  min-width: 100px;
  min-height: 50px;
}

.blockday .location .timeScale {
  margin-left: 100px;
}

.blockday .location .block {
  z-index: 3;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  min-height: 50px;
  margin-right: 0;
  font-size: .8rem;
  line-height: .9rem;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.blockday .location .block .header {
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #4d5980;
  height: 15px;
  padding-left: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: .6rem;
  font-weight: bold;
  line-height: 15px;
}

.blockday .location .block .header.premiere_WP {
  background-color: #bfcc4d;
}

.blockday .location .block .header.premiere_DP {
  background-color: #ff4066;
}

.blockday .location .block .header.premiere_EP {
  background-color: #0019e6;
}

.blockday .location .block .content {
  transform-origin: 0 0;
  padding: 5px;
  transform: scale(.7);
}

.blockday .location .block .section {
  color: #4d5980;
  font-family: GraphikThin;
  font-size: .8rem;
}

.blockday .location .block .section .Regular {
  color: #bfcc4d;
}

.blockday .location .block .premiere {
  float: right;
  padding-right: 10px;
}

.blockday .location .block .pretitle {
  color: #ff4066;
  margin-right: 5px;
  display: inline-block;
}

.blockday .location .block .title {
  font-family: GraphikBold;
  font-size: 1rem;
  display: inline-block;
}

.blockday .location .block .posttitle {
  color: #ff4066;
  display: inline-block;
}

.blockday .location .block .subtitle {
  font-family: GraphikBold;
  font-size: .8rem;
}

.blockday .location .block .directors {
  font-family: GraphikRegular;
}

.blockday .location .block .countriesLine {
  font-family: GraphikBold;
}

.blockday .location .block .countries, .blockday .location .block .year, .blockday .location .block .length {
  display: inline-block;
}

.blockday .location .block .comma {
  padding: 0 4px 0 0;
}

.blockday .location .block .text {
  padding-top: .5rem;
  font-size: .6rem;
}

.json-viewer {
  color: #000;
  padding-left: 20px;
}

.json-viewer ul {
  border-left: 1px dotted #ccc;
  margin: 0 0 0 1px;
  padding-left: 2em;
  list-style-type: none;
}

.json-viewer .hide {
  display: none;
}

.json-viewer .type-string {
  color: #0b7500;
}

.json-viewer .type-date {
  color: #cb7500;
}

.json-viewer .type-boolean {
  color: #1a01cc;
  font-weight: bold;
}

.json-viewer .type-number {
  color: #1a01cc;
}

.json-viewer .type-null, .json-viewer .type-undefined {
  color: #90a;
}

.json-viewer a.list-link {
  color: #000;
  text-decoration: none;
  position: relative;
}

.json-viewer a.list-link:before {
  color: #aaa;
  content: "▼";
  width: 1em;
  display: inline-block;
  position: absolute;
  left: -1em;
}

.json-viewer a.list-link.collapsed:before {
  content: "▶";
}

.json-viewer a.list-link.empty:before {
  content: "";
}

.json-viewer .items-ph {
  color: #aaa;
  padding: 0 1em;
}

.json-viewer .items-ph:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
}

::selection {
  background: #ff0;
}

html, body {
  min-height: 100vh;
  font-family: Lato;
}

.root {
  padding: 10px;
}

:focus {
  outline: initial;
}

.header .headerlogo {
  float: right;
}

.cui-dialog-content {
  width: 80vw;
}

.login .cui-dialog-content {
  max-width: 350px;
}

.spinner {
  animation: 1s infinite spin-animation;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.tab.show {
  display: block;
}

.tab.hide {
  display: none;
}

.generator .progress {
  border: 1p solid black;
  background-color: #fff;
  width: 100%;
  height: 10px;
  margin: 5px;
}

.generator .progress .bar {
  background-color: coral;
  height: 8px;
  margin: 1px;
}

/*# sourceMappingURL=index.f2941544.css.map */
