
$BLOCK_WIDTH: 2000px;
$BLOCK_HEIGHT: 50px;
$LOCATION_WIDTH: 100px;

.blockday{
    position: relative;
    border-top: 2px solid black;
    // overflow-x: hidden;
    &.singleblock{
        overflow: initial;
        .block{
            position: relative !important;
            left: 0 !important;
        }
    }
    .debug{
        font-size: .5rem;
        font-weight: initial;
    }

    .timeScale{
        // width: $BLOCK_WIDTH;
        margin-left: $LOCATION_WIDTH;
        white-space: nowrap;
        .hour{
            display: inline-block;
            // min-width: 50px;
            border-left: 2px solid black;
        }
    }

    .timeScaleLines{
        position: absolute;
        left: $LOCATION_WIDTH;
        top: 0;
        // width: $BLOCK_WIDTH;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        .line{
            display: inline-block;
            height: 100%;
            border-left: 1px solid rgba( 0,0,0,.1);
        }
    }
    .location{
        position: relative;
        background-color: white;
        color: black;
        border-top: 1px solid black;

        .location_title{
            min-height: $BLOCK_HEIGHT;
            min-width: $LOCATION_WIDTH;
            border-right: 1px solid black;
        }
        .timeScale{
            // width: $BLOCK_WIDTH;
            margin-left: 100px;
        }
        .block{
            position: absolute;
            margin-right: 0;
            min-height: $BLOCK_HEIGHT;
            border: 1px solid black;
            background-color: white;
            z-index: 3;
            overflow-y: hidden;
            font-size: .8rem;
            line-height: .9rem;
            top: 0;
            overflow: hidden;
            cursor: pointer;
            
            .header{
                height: 15px;
                background-color: $IDFA_GREY;
                font-size: .6rem;
                color: white;
                vertical-align: middle;
                line-height: 15px;
                font-weight: bold;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                padding-left: 5px;
                white-space: nowrap;
                
                &.premiere_WP{
                    background-color: $IDFA_GREEN;
                }
                &.premiere_DP{
                    background-color: $IDFA_RED;
                }
                &.premiere_EP{
                    background-color: $IDFA_BLUE;
                }
            }
            .content{
                padding: 5px;
                transform: scale(0.7);
                transform-origin: top left;
            }
            .section{
                color: $IDFA_GREY;
                font-family: 'GraphikThin';
                font-size: .8rem;
                .Regular{
                    color: $IDFA_GREEN;
                }
            }
            .premiere{
                float: right;
                padding-right: 10px;
            }
            .pretitle{
                color: $IDFA_RED;
                display: inline-block;
                margin-right: 5px;
            }
            .title{
                display: inline-block;
                font-family: GraphikBold;
                font-size: 1rem;
            }
            .posttitle{
                display: inline-block;
                color: $IDFA_RED;
            }

            .subtitle{
                font-family: GraphikBold;
                font-size: 0.8rem;
            }
            .directors{
                font-family: GraphikRegular;
            }
            .countriesLine{
                font-family: GraphikBold;
            }
            .countries, .year, .length{
                display: inline-block;
            }
            .comma{
                padding: 0 4px 0 0;
            }
            .text{
                font-size: .6rem;
                padding-top: .5rem;
            }        
        }
    }
}