@use "sass:math";
@import '../../_shared/variables';
@import '../../_shared//mixins';

@mixin cui-tag-sizing($height, $padding, $font-size) {
  $tag-padding-x: floor(math.div($padding, 1.5));
  $tag-padding-y: floor(math.div($padding, 6));
  $tag-font-size: floor(math.div($font-size, 1.05));

	font-size: $font-size;
  padding: $tag-padding-y $tag-padding-x;
  margin-right: $tag-padding-x;

  &.cui-tag-removable {
    .cui-icon {
      margin-left: $tag-padding-x;
    }
  }
}

@mixin cui-tag-style($color, $bg-color, $border-color) {
  color: $color;
  background: $bg-color;
  border: solid 1px $border-color;
}

.cui-tag {
  @include cui-tag-sizing($cui-base-height, $cui-base-padding, $cui-font-size);
  @include cui-tag-style(
    $cui-base-text-color,
    $cui-hover-color,
    tint($blue-grey100, 10%)
  );

	position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  border-radius: $cui-border-radius;

  &.cui-rounded {
		border-radius: $cui-border-radius-rounded;
  }

  &.cui-primary,
  &.cui-positive,
  &.cui-negative,
  &.cui-warning {
    .cui-icon {
      color: $blue-grey50;

      &:hover {
        color: white;
      }
    }
  }

  @each $size in $cui-sizes {
    &.cui-#{$size},
    .cui-#{$size} & {
      @include cui-tag-sizing(
        map-get($cui-height-map, $size),
        map-get($cui-padding-map, $size),
        map-get($cui-font-size-map, $size)
      );
    }
  }

  @each $intent in $cui-intents {
    &.cui-#{$intent} {
      $color: map-get($cui-bg-color-map, $intent);

      @include cui-tag-style(
        $white,
        $color,
        shade($color, 20%)
      );
    }
  }
}
