@use "sass:math";
@import '../../_shared/variables';

$cui-spinner-animation-duration: 500ms;
$cui-spinner-transition-duration: 300ms;

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin cui-spinner-sizing($height, $font-size) {
  .cui-spinner-icon {
    $size: floor(math.div($height, 2));
    height: $size;
    width: $size;
  }

  .cui-spinner-message {
    font-size: $font-size;
  }
}

@mixin cui-spinner-style($color) {
  .cui-spinner-icon {
    border-top-color: $color !important;
  }
}

.cui-spinner {
  @include cui-spinner-sizing($cui-base-height, $cui-font-size);
  @include cui-spinner-style($cui-primary-bg-color);

  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
	opacity: 0;
  transition:
    opacity $cui-spinner-transition-duration ease-in-out,
    visibility 0s ease-in-out $cui-spinner-transition-duration,
    background $cui-spinner-transition-duration ease-in-out;
	user-select: none;
	will-change: opacity;
	visibility: hidden;

	&.cui-spinner-active {
		opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  &.cui-spinner-fill {
    position:absolute;
    top:0;
    left:0;
    height:100% !important;
    width:100% !important;
    z-index: $cui-z-index-overlay;
  }

  &.cui-spinner-bg {
    background: rgba($white, 0.5);
  }

  @each $size in $cui-sizes {
    &.cui-#{$size},
    .cui-#{$size} & {
      @include cui-spinner-sizing(
        map-get($cui-height-map, $size),
        map-get($cui-font-size-map, $size)
      );
    }
  }

  @each $intent in $cui-intents {
    &.cui-#{$intent},
    .cui-#{$intent} & {
      @include cui-spinner-style(map-get($cui-bg-color-map, $intent))
    }
  }
}

.cui-spinner-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cui-spinner-icon {
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 2px solid $cui-base-border-color;
  border-top-color: red !important;
  border-radius: 50%;
  animation: loading $cui-spinner-animation-duration infinite linear;
}

.cui-spinner-message {
  margin-top: 10px;
  color: $blue-grey500;
}
