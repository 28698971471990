@import '../../_shared/variables';

.cui-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  background-color: $white;

  tbody {
    width: 100%;
  }

  th, td {
    border-bottom: solid 1px $cui-base-border-color;
    padding: $cui-base-size;
    font-size: $cui-font-size;
    text-align:left;
  }

  &:not(.cui-table-bordered) tr:last-child {
    td, th { border-bottom: none}
  }

  &.cui-table-bordered {
    td, th {
      border: solid 1px $cui-base-border-color;
    }
  }

  &.cui-table-striped {
    tr:nth-of-type(odd) {
      background: $cui-hover-color;
    }
  }

  &.cui-table-interactive {
    tr {
      cursor: pointer;
    }

    tr:hover {
      background: $cui-hover-color;
    }

    tr:active {
      background: $blue-grey50;
    }
  }
}
