  // greyscale colors
  
  $white: #ffffff !default;

  $grey50: #fafafa !default;
  $grey100: #f5f5f5 !default;
  $grey200: #eeeeee !default;
  $grey300: #e0e0e0 !default;
  $grey400: #bdbdbd !default;
  $grey500: #9e9e9e !default;
  $grey600: #757575 !default;
  $grey700: #616161 !default;
  $grey800: #424242 !default;
  $grey900: #212121 !default;

  $blue-grey50: #eceff1 !default;
  $blue-grey100: #cfd8dc !default;
  $blue-grey200: #b0bec5 !default;
  $blue-grey300: #90a4ae !default;
  $blue-grey400: #78909c !default;
  $blue-grey500: #607d8b !default;
  $blue-grey600: #546e7a !default;
  $blue-grey700: #455a64 !default;
  $blue-grey800: #37474f !default;
  $blue-grey900: #263238 !default;

  // core colors

  $red50: #ffebee !default;
  $red100: #ffcdd2 !default;
  $red200: #ef9a9a !default;
  $red300: #e57373 !default;
  $red400: #ef5350 !default;
  $red500: #f44336 !default;
  $red600: #e53935 !default;
  $red700: #d32f2f !default;
  $red800: #c62828 !default;
  $red900: #b71c1c !default;

  $purple50: #f3e5f5 !default;
  $purple100: #e1bee7 !default;
  $purple200: #ce93d8 !default;
  $purple300: #ba68c8 !default;
  $purple400: #ab47bc !default;
  $purple500: #9c27b0 !default;
  $purple600: #8e24aa !default;
  $purple700: #7b1fa2 !default;
  $purple800: #6a1b9a !default;
  $purple900: #4a148c !default;

  $deep-purple50: #ede7f6 !default;
  $deep-purple100: #d1c4e9 !default;
  $deep-purple200: #b39ddb !default;
  $deep-purple300: #9575cd !default;
  $deep-purple400: #7e57c2 !default;
  $deep-purple500: #673ab7 !default;
  $deep-purple600: #5e35b1 !default;
  $deep-purple700: #512da8 !default;
  $deep-purple800: #4527a0 !default;
  $deep-purple900: #311b92 !default;

  $indigo50: #e8eaf6 !default;
  $indigo100: #c5cae9 !default;
  $indigo200: #9fa8da !default;
  $indigo300: #7986cb !default;
  $indigo400: #5c6bc0 !default;
  $indigo500: #3f51b5 !default;
  $indigo600: #3949ab !default;
  $indigo700: #303f9f !default;
  $indigo800: #283593 !default;
  $indigo900: #1a237e !default;

  $blue50: #e3f2fd !default;
  $blue100: #bbdefb !default;
  $blue200: #90caf9 !default;
  $blue300: #64b5f6 !default;
  $blue400: #42a5f5 !default;
  $blue500: #2196f3 !default;
  $blue600: #1e88e5 !default;
  $blue700: #1976d2 !default;
  $blue800: #1565c0 !default;
  $blue900: #0d47a1 !default;

  $light-blue50: #e1f5fe !default;
  $light-blue100: #b3e5fc !default;
  $light-blue200: #81d4fa !default;
  $light-blue300: #4fc3f7 !default;
  $light-blue400: #29b6f6 !default;
  $light-blue500: #03a9f4 !default;
  $light-blue600: #039be5 !default;
  $light-blue700: #0288d1 !default;
  $light-blue800: #0277bd !default;
  $light-blue900: #01579b !default;

  $cyan50: #e0f7fa !default;
  $cyan100: #b2ebf2 !default;
  $cyan200: #80deea !default;
  $cyan300: #4dd0e1 !default;
  $cyan400: #26c6da !default;
  $cyan500: #00bcd4 !default;
  $cyan600: #00acc1 !default;
  $cyan700: #0097a7 !default;
  $cyan800: #00838f !default;
  $cyan900: #006064 !default;

  $teal50: #e0f2f1 !default;
  $teal100: #b2dfdb !default;
  $teal200: #80cbc4 !default;
  $teal300: #4db6ac !default;
  $teal400: #26a69a !default;
  $teal500: #009688 !default;
  $teal600: #00897b !default;
  $teal700: #00796b !default;
  $teal800: #00695c !default;
  $teal900: #004d40 !default;

  $green50: #e8f5e9 !default;
  $green100: #c8e6c9 !default;
  $green200: #a5d6a7 !default;
  $green300: #81c784 !default;
  $green400: #66bb6a !default;
  $green500: #4caf50 !default;
  $green600: #43a047 !default;
  $green700: #388e3c !default;
  $green800: #2e7d32 !default;
  $green900: #1b5e20 !default;

  $light-green50: #f1f8e9 !default;
  $light-green100: #dcedc8 !default;
  $light-green200: #c5e1a5 !default;
  $light-green300: #aed581 !default;
  $light-green400: #9ccc65 !default;
  $light-green500: #8bc34a !default;
  $light-green600: #7cb342 !default;
  $light-green700: #689f38 !default;
  $light-green800: #558b2f !default;
  $light-green900: #33691e !default;

  $orange50: #fff3e0 !default;
  $orange100: #ffe0b2 !default;
  $orange200: #ffcc80 !default;
  $orange300: #ffb74d !default;
  $orange400: #ffa726 !default;
  $orange500: #ff9800 !default;
  $orange600: #fb8c00 !default;
  $orange700: #f57c00 !default;
  $orange800: #ef6c00 !default;
  $orange900: #e65100 !default;
