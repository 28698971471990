
@font-face {
    font-family: GraphikRegular;
    src: url('fonts/Graphik-Regular-App.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: GraphikBold;
    src: url('fonts/Graphik-Bold-App.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: GraphikThin;
    src: url('fonts/Graphik-Thin-App.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
pre{
    border: initial;
}

.guestIcon{
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: content-box;
    // margin-right: 10px;
    display: inline-block;
    background-image: url(img/guestIcon.png);
}

#filmAZ{
    background: white;
    color: black;
    font-family: GraphikRegular;
    .header{
        height: 15px;
        // background-color: $IDFA_GREY;
    }
    .section{
        color: $IDFA_GREY;
        text-align: right;
        padding-right: 10px;
        font-family: 'GraphikThin';
        .Regular{
            color: $IDFA_GREEN;
        }
    }
    .premiere{
        float: right;
        padding-right: 10px;
        &::before{
            content: "{";
        }
        &::after{
            content: "}";
        }
    }
    .pretitle{
        display: inline-block;
    }
    .title{
        display: inline-block;
        font-family: GraphikBold;
        font-size: 1.5rem;
    }
    .posttitle{
        display: inline-block;
    }
    .subtitle{
        font-family: GraphikBold;
        font-size: 1rem;
    }
    .directors{
        font-family: GraphikRegular;
    }
    .countriesLine{
        font-family: GraphikBold;
        div{
            display: inline-block;
        }
    }
    .countries, .year, .length{
        display: inline-block;
    }
    .comma{
        padding: 0 4px 0 0;
    }
    .text{
        padding-top: 1rem;
    }
    .shows{
        font-family: Arial;
        padding-top: .5rem;
        span{
            display: inline-block;
        }
        .weekday{
            width: 25px;
            text-transform: uppercase;
        }
        .day{
            width: 20px;
            font-weight: bold;
        }
        .time{
            width: 50px;
        }
        .location{
            font-weight: bold;
        }
        .postTitle{
            float: right;
            color: $IDFA_RED;
        }

    }
    .label{
        width: 50px;
        height: 43px;
        float: right;
        background-size: contain;
        background-repeat: no-repeat;
        background-clip: content-box;
        margin-right: 10px;
        display: block;
        &.IDFA_Bertha_Fund{
            background-image: url(img/bertha-fund.png);
        }
        &.IDFA_Forum{
            background-image: url(img/forum.png);
        }
        &.IDFAcademy_Results{
            background-image: url(img/academy.png);
        }
    }
    .sectionNames{
        display: inline-block;
        // background: #f15a29;
        padding: 3px;
    }
    pre{
        margin-bottom: 0;
        font-family: "Graphik Regular";
    }
}


#showBlock{
    background: white;
    color: black;
    font-family: GraphikRegular;
    width: 100%;
    height: 200px;
    .location{
        font-size: 3rem;
        float: left;
        width: 300px;
        height: 200px;
        border-right: 1px solid black;
    }
    .block{
        // float: right;
        height: 200px;
        margin-right: 100px;
        border: 1px solid black;
        .header{
            height: 15px;
            background-color: $IDFA_GREY;
            font-size: .6rem;
            color: white;
            vertical-align: middle;
            line-height: 15px;
            font-weight: bold;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            padding-left: 5px;
        }

        .section{
            color: $IDFA_GREY;
            font-family: 'GraphikThin';
            font-size: .6rem;
            .Regular{
                color: $IDFA_GREEN;
            }
        }
        .premiere{
            float: right;
            padding-right: 10px;
        }
        .pretitle{
            color: $IDFA_RED;
            display: inline-block;
        }
        .title{
            display: inline-block;
            font-family: GraphikBold;
            font-size: 1.5rem;
        }
        .posttitle{
            display: inline-block;
            color: $IDFA_RED;
        }

        .subtitle{
            font-family: GraphikBold;
            font-size: 1.7rem;
        }
        .directors{
            font-family: GraphikRegular;
        }
        .countriesLine{
            font-family: GraphikBold;
        }
        .countries, .year, .length{
            display: inline-block;
        }
        .comma{
            padding: 0 4px 0 0;
        }
        .text{
            font-size: .6rem;
            padding-top: .5rem;
        }
    }
}