@import "../node_modules/construct-ui/src/index.scss";
@import "../node_modules/tabulator-tables/src/scss/tabulator.scss";
@import "./css/vars.scss";
@import "./css/preview.scss";
@import "./css/blocks.scss";
@import "./css/jsonviewer.scss";
@import "./css/fonts.scss";

::selection { background: yellow; }

html, body{
    min-height: 100vh;
	font-family: Lato;
}

.root{
    padding: 10px;
    // background-color: black;
    // color: white;
}

:focus{
    outline: initial;
}
.header{
    .headerlogo{
        float: right;
    }
}

.cui-dialog-content{
    width: 80vw;
}

.login .cui-dialog-content{
    max-width: 350px;    
}


.spinner {
    animation: spin-animation 1s infinite;
    display: inline-block;
}
  
@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.tab{
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }

}
.generator{
    .progress{
        height: 10px;
        background-color: white;
        border: 1p solid black;
        width: 100%;
        margin: 5px;
        .bar{
            height: 8px;
            background-color: coral;
            margin: 1px;
        }
    }
}