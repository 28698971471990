@use "sass:math";
@import '../../_shared/mixins';
@import '../../_shared/variables';

.cui-tree {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cui-tree-node-list {
	margin:0;
	padding-left: 20px;
	list-style:none;
}

.cui-tree-node-content {
	display: flex;
	align-items: center;
	padding: math.div($cui-base-size, 2);

	&:hover {
		background-color: $cui-base-bg-color-hover;
	}
}

.cui-tree-node-caret,
.cui-tree-node-caret-none {
	text-align:center;
	position:relative;
}

.cui-tree-node-caret-none {
	opacity:0;
	visibility: hidden;
}

.cui-tree-node-caret {
	color: $cui-text-color;
	display: inline-block;
  transition: transform ($cui-transition-duration * 2) $cui-transition-ease;
  transform-origin: center center;
  cursor:pointer;
  margin-right: 5px;

	&.cui-tree-node-caret-open {
    transform: rotate(90deg);
    transform-origin: center center;
	}
}

.cui-tree-node-label {
	@include cui-overflow-ellipsis();

	flex: 1 1 auto;
	position:relative;
  user-select:none;
  font-size: $cui-font-size;

	span {
		display:inline;
	}
}

.cui-tree-node-content-right {
  display:flex;
  margin-left: 5px;
}

.cui-tree-node-content-left {
  margin-right: 5px;
  display:flex;
}

.cui-tree-node.cui-tree-node-selected {
  > .cui-tree-node-content {
    background: $blue-grey50;
  }
}
