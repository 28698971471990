@use "sass:math";
@import '../../_shared/variables';
@import '../../_shared/mixins';

@mixin cui-radio-sizing($size) {
  input:checked ~ .cui-control-indicator:after {
    height: floor(math.div($size, 2));
    width: floor(math.div($size, 2));
  }
}

@mixin cui-radio-style($bg-color-active, $border-color) {
  .cui-control-indicator {
    border: solid 1px $border-color;
  }

  input:checked ~ .cui-control-indicator {
    border: solid 1px $bg-color-active;

    &:after {
      background: $bg-color-active;
    }
  }
}

.cui-radio {
  @include cui-radio-sizing($cui-control-base);
  @include cui-radio-style(
    $cui-primary-bg-color,
    $cui-base-border-color
  );

  .cui-control-indicator {
    border-radius: 50%;
  }

   input:checked ~ .cui-control-indicator {
    background: white;
    color:white;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  @each $size in $cui-sizes {
    &.cui-#{$size} {
      @include cui-radio-sizing(map-get($cui-control-map, $size))
    }
  }

  @each $intent in $cui-intents {
    &.cui-#{$intent} {
      @include cui-radio-style(
        map-get($cui-bg-color-map, $intent),
        map-get($cui-border-color-map, $intent)
      )
    }
  }
}

.cui-radio-group {
  .cui-radio {
    margin-right: floor($cui-control-base * 1.5);
  }

  @each $size in $cui-sizes {
    &.cui-#{$size} .cui-control {
      @include cui-control-sizing(
        map-get($cui-control-map, $size),
        map-get($cui-font-size-map, $size)
      );

      @include cui-radio-sizing(map-get($cui-control-map, $size));
    }

    &.cui-#{$size} .cui-radio {
      margin-right: floor(map-get($cui-control-map, $size) * 1.5);
    }
  }
}
